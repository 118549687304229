import React, { useEffect, useState } from "react";
import Form from "@rjsf/core";
import { Index } from "./App";
import GSISchema from "./forms/GSI";
import ModalBase from "./ModalBase";
import Button from "./Button";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { Flex } from "rebass";
import { IndexProjection } from "./types/Model";

type NewGSIModalProps = {
  isVisible: boolean;
  onClose: () => void;
  addGSI: (index: Index) => void;
  indexes: Index[];
};

const NewGSIModal = (props: NewGSIModalProps) => {
  const formRef = React.useRef(null);
  const [state, setState] = useState({
    indexName: "",
    partitionKey: "",
    sortKey: "",
    projection: "ALL" as IndexProjection,
  });

  const validate = (formData: { indexName: string }, errors: any) => {
    if (props.indexes.find((i) => i.name === formData.indexName)) {
      errors.indexName.addError(
        `You already have a GSI called "${formData.indexName}"`
      );
    }
    return errors;
  };

  useEffect(() => {
    setState({
      indexName: "",
      partitionKey: "",
      sortKey: "",
      projection: "ALL" as IndexProjection,
    });
  }, [props.isVisible]);

  return (
    <ModalBase
      onClose={props.onClose}
      modalTitle={"Add GSI"}
      isVisible={props.isVisible}
      content={() => (
        <div>
          <Form
            className="new-gsi-modal rjsf"
            schema={GSISchema as any}
            formData={state}
            showErrorList={false}
            onChange={(e: any) => setState(e.formData)}
            onError={console.error}
            liveValidate
            ref={formRef}
            validate={validate}
            uiSchema={{
              expression: {
                "ui:widget": "hidden",
              },
            }}
          />
        </div>
      )}
      footer={() => (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          style={{ borderTop: "1px solid #e4e4e4" }}
          p={3}
        >
          <Button
            icon={() => (
              <PlusCircleIcon
                width={16}
                height={16}
                color={"#fff"}
                style={{
                  marginBottom: -3,
                }}
              />
            )}
            className="button-save-item"
            style={{
              padding: "10px",
              backgroundColor:
                formRef &&
                formRef.current &&
                (formRef.current as any).state.errors.length !== 0
                  ? "rgb(158, 158, 158)"
                  : "#4997f7",
              color: "white",
            }}
            color="white"
            disabled={
              !!(
                formRef &&
                formRef.current &&
                (formRef.current as any).state.errors.length !== 0
              )
            }
            onClick={() => {
              props.addGSI({
                sort: state.sortKey,
                hash: state.partitionKey,
                projection: state.projection as IndexProjection,
                name: state.indexName,
                isSelected: false,
              });
              props.onClose();
            }}
          >
            Add Global Secondary Index
          </Button>
        </Flex>
      )}
    />
  );
};

export default NewGSIModal;
