import React from "react";
import Select from "react-select";

interface SelectorProps {
  defaultValue: string;
  options: string[];
  setSelectedValue: (value: any) => void;
  styles?: any;
}

const Selector: React.FC<SelectorProps> = (props: SelectorProps) => {
  const optionsMapped = props.options.map((item) => {
    return { value: item, label: item };
  });
  const defaultValue = optionsMapped.find((profile) => profile.value === props.defaultValue);

  return (
    <Select
      options={optionsMapped}
      placeholder="Saved Queries"
      defaultValue={defaultValue}
      value={defaultValue}
      onChange={(e: any) => props.setSelectedValue(e.value)}
      styles={{
        control: (provided) => ({
          ...provided,
          minWidth: "200px",
          ...props.styles,
        }),
      }}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#f8f8f8",
          primary50: "#f8f8f8",
          primary: "#d5d5d5",
        },
      })}
    />
  );
};

export default Selector;
