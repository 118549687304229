const SettingsSchema = {
  type: "object",
  required: ["attributesTruncateLength", "entityTypeAttributePosition"],
  properties: {
    attributesTruncateLength: {
      type: "number",
      title: "Attributes Truncate Limit",
      description:
        "The limit of characters rendered in the 'Other Attributes' column.",
    },
    entityTypeAttributePosition: {
      type: "string",
      title: "Entity Type Attribute Position",
      enum: ["After Primary Index", "After GSIs"],
      description: "Partition key attribute name",
    },
    singleTableDesignerStyle: {
      type: "string",
      title: "Font Display Style",
      enum: ["sans serif", "monospace"],
      description: "Font Family",
    },
  },
};

export default SettingsSchema;
