import React from "react";
import { Button, ButtonProps } from "rebass";

interface BtnProps extends ButtonProps {
  icon?: () => any;
  disabled?: boolean;
  color?: string;
}

const Btn: React.FC<BtnProps> = (props: BtnProps) => (
  <Button
    {...props}
    sx={{
      minWidth: "120px",
      padding: "2px",
      outline: "0",
      borderRadius: 0,
      border: "1px solid #ddd",
      cursor: props.disabled ? "not-allowed" : "pointer",
    }}
  >
    <div
      style={{
        display: "inline",
        marginRight: 4,
        cursor: props.disabled ? "not-allowed" : "pointer",
      }}
    >
      {props.icon ? props.icon() : undefined}
    </div>
    <div
      style={{
        display: "inline",
        color: props.color || (props.disabled ? "#9e9e9e" : "#464646"),
      }}
    >
      {props.children}
    </div>
  </Button>
);

export default Btn;
