import { State } from "./App";
import { AttributeType, StringOperator } from "./FilterExpression";
import { convertToDDBType } from "./helpers/convertToDDBJSON";
import { getSavedQueries } from "./Query";
import {
  DynamoDBType,
  FilterType,
  GlobalSecondaryIndex,
  Index,
  Indexes,
  IndexProjection,
  Item,
  ItemModel,
  Model,
  Models,
  NonKeyAttribute,
  Query,
  QueryOperation,
} from "./types/Model";

export const exportModel = (state: State, entityTypeAttributeName: string): Model => {
  const model: Model = {
    ModelName: state.modelName,
    ModelMetadata: {
      Author: "Dynobase",
      DateCreated: "to-be-changed",
      DateLastModified: new Date().toISOString(),
      Description: "",
      AWSService: "Amazon DynamoDB",
      Version: "2.0",
    },
    DataModel: [
      {
        TableName: "data",
        KeyAttributes: {
          PartitionKey: {
            AttributeName: "PK",
            AttributeType: "S",
          },
          SortKey: {
            AttributeName: "SK",
            AttributeType: "S",
          },
        },
        NonKeyAttributes: computeNonKeyAttributes(state),
        GlobalSecondaryIndexes: computeGSIs(state),
        TableData: computeTableData(state, entityTypeAttributeName),
        ModelSchema: {
          indexes: computeIndexes(state),
          models: computeModels(state),
          version: "0.1.0",
          params: {
            typeField: entityTypeAttributeName,
          },
          format: "onetable:1.0.0",
          queries: computeQueries(state),
        },
      },
    ],
    ModelSchema: {
      indexes: computeIndexes(state),
      models: computeModels(state),
      version: "0.1.0",
      params: {
        typeField: entityTypeAttributeName,
      },
      format: "onetable:1.0.0",
      queries: computeQueries(state),
    },
  };

  return model;
};

const computeIndexes = (state: State) => {
  const indexes: Indexes = {};
  state.indexes.forEach((index, i) => {
    indexes[index.name] = {
      hash: index.hash,
      sort: index.sort,
    };

    // non primary, assuming first one is the primary
    if (i > 0) {
      (indexes[index.name] as Index).projection = index.projection!;
    }
  });
  return indexes;
};

const computeQueries = (state: State): Record<string, Query> => {
  const savedQueries = getSavedQueries(state.modelName);
  const queries: Record<string, Query> = {};

  savedQueries.forEach((query) => {
    queries[query.name] = {
      hash: query.pkValue,
      sort: query.skValue,
      limit: "100",
      index: query.currentIndex,
      schema: "Current",
      type: "Query",
      operation: StringOperationToQueryOperation(query.skOperator),
      filters: query.filterExpressions.map((filter) => ({
        operation: StringOperationToQueryOperation(filter.operator as StringOperator),
        combine: "And",
        field: filter.attributeName,
        value: filter.attributeValue,
        type: FilterAttributeType(filter.attributeType),
      })),
    };
  });

  return queries;
};

const StringOperationToQueryOperation = (op: StringOperator): QueryOperation => {
  switch (op) {
    case StringOperator.Equal:
      return "Equal";
    case StringOperator.GreaterEqualThan:
      return "Greater than or equal";
    case StringOperator.GreaterThan:
      return "Greater than";
    case StringOperator.LessEqualThan:
      return "Less than or equal";
    case StringOperator.LessThan:
      return "Less than";
    case StringOperator.BeginsWith:
      return "Begins with";
    default:
      return "Equal";
  }
};

const FilterAttributeType = (type: AttributeType): FilterType => {
  switch (type) {
    case AttributeType.String:
      return "string";
    case AttributeType.Binary:
      return "binary";
    case AttributeType.Boolean:
      return "boolean";
    case AttributeType.Number:
      return "number";
    default:
      return "string";
  }
};

const computeGSIs = (state: State): GlobalSecondaryIndex[] => {
  return state.indexes.slice(1).map((index) => {
    const gsi: GlobalSecondaryIndex = {
      IndexName: index.name,
      KeyAttributes: {
        PartitionKey: {
          AttributeName: index.hash,
          AttributeType: "S",
        },
        SortKey: {
          AttributeName: index.sort,
          AttributeType: "S",
        },
      },
      Projection: {
        ProjectionType: index.projection as IndexProjection,
      },
    };

    if (index.projectedAttributes) {
      gsi.Projection.NonKeyAttributes = index.projectedAttributes;
    }

    return gsi;
  });
};

const computeModels = (state: State) => {
  const modelsArray: ItemModel[] = state.data.map((item) => {
    const model: Record<string, DynamoDBType> = {};

    Object.entries(item).forEach(([key, attribute]) => {
      if (key === "type") {
        model[key] = {
          type: attribute.type.toLowerCase(),
          value: attribute.value,
        } as DynamoDBType;
      } else {
        model[key] = {
          type: attribute.type?.replace("Composite", "").toLowerCase(),
        } as DynamoDBType;

        if (attribute.template) {
          model[key].value = attribute.template;
          model[key].type = "string";
        }
      }
    });

    return model as ItemModel;
  });

  const models: Models = {};
  modelsArray.forEach((item) => {
    if (item.type) {
      models[item.type.value!] = item;
    }
  });

  return models;
};

const computeTableData = (state: State, entityTypeAttributeName: string): Item[] => {
  return state.data
    .map((item) => {
      if (item && item[entityTypeAttributeName]) {
        const ddbType = convertToDDBType(item[entityTypeAttributeName].type) as any;

        const modelItem: any = {
          [entityTypeAttributeName]: {
            [ddbType]: item[entityTypeAttributeName].value as string,
          } as any,
        };

        Object.entries(item).forEach(([key, attribute]) => {
          const dynamoDBType = convertToDDBType(attribute.type);

          modelItem[key] = {
            [dynamoDBType]: attribute.value, // todo - compute value from template if possible
          } as any;

          // Move back to the schema
          // todo - move to schema (item.value)
          // if (attribute.template) {
          //   modelItem[key].template = attribute.template;
          // }
        });

        return modelItem;
      }
      return undefined;
    })
    .filter(Boolean) as Item[];
};

const computeNonKeyAttributes = (state: State): NonKeyAttribute[] => {
  const pkName = state.indexes[0].hash;
  const skName = state.indexes[0].sort;

  const nonKeyAttributes: NonKeyAttribute[] = [];
  state.data.forEach((item) => {
    Object.entries(item).forEach(([key, attribute]) => {
      if (key !== pkName && key !== skName) {
        const dynamoDBType = convertToDDBType(attribute.type);

        if (!nonKeyAttributes.find((n) => n.AttributeName === key)) {
          nonKeyAttributes.push({
            AttributeName: key,
            AttributeType: dynamoDBType,
          });
        }
      }
    });
  });

  return nonKeyAttributes;
};
