/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactTooltip from "rc-tooltip";
import { Box } from "rebass";

export type ToolbarIconProps = {
  id: string;
  description: string;
  onClick: (...args: any[]) => void;
  Icon: any;
  color?: string;
  fill?: string;
  boxStyle?: any;
  component?: any;
  afterPseudoElementStyle?: any;
  place?: string;
  type: "icon";
  hideInReadOnlyMode?: boolean;
};

export const ToolbarIcon = ({
  id,
  description,
  onClick,
  Icon,
  component,
  color,
  fill,
  boxStyle,
  afterPseudoElementStyle,
  place,
}: ToolbarIconProps) => {
  return (
    <>
      {component ? (
        component()
      ) : (
        <ReactTooltip
          id={id}
          overlay={<span>{description}</span>}
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0}
          placement={place || "bottom"}
        >
          <Box
            sx={{ cursor: "pointer", ...boxStyle }}
            p={2}
            onClick={onClick}
            id={id}
          >
            <Box
              className="toolbar-icon"
              p={2}
              style={{ position: "relative" }}
            >
              <Icon
                fill={fill ?? "transparent"}
                color={color ?? "black"}
                width={16}
                height={16}
              />
              {afterPseudoElementStyle ? (
                <div style={afterPseudoElementStyle} />
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </ReactTooltip>
      )}
    </>
  );
};
