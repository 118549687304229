import { Attribute } from "./App";
import { getTemplateDetailsFromOriginal } from "./EditableCell";
import { FilterExpression, StringOperator } from "./FilterExpression";
import { QueryBuilderState } from "./QueryBuilder";

export type SavedQuery = QueryBuilderState & { name: string };

export const evaluateRowAgainstFilters = (row: Record<string, Attribute>, filters: FilterExpression[]): boolean => {
  const filtersResults = filters.map((filter) => evaluateRowAgainstFilter(row, filter));

  return filtersResults.reduce((prevVal, currentVal) => prevVal && currentVal, true);
};

const evaluateRowAgainstFilter = (row: Record<string, Attribute>, filter: FilterExpression): boolean => {
  let result = false;
  let value = "";
  if (row[filter.attributeName]?.template) {
    const { renderedValue } = getTemplateDetailsFromOriginal(row[filter.attributeName]?.template!, row);
    value = renderedValue;
  } else if (row[filter.attributeName]?.value) {
    value = row[filter.attributeName]?.value as string;
  }

  switch (filter.operator) {
    case StringOperator.Equal:
      if (!filter.attributeValue || value === filter.attributeValue || filter.attributeValue.length === 0)
        result = true;
      break;
    case StringOperator.NotEqual:
      if (value !== filter.attributeValue) result = true;
      break;
    case StringOperator.GreaterThan:
      if (value > filter.attributeValue) result = true;
      break;
    case StringOperator.GreaterEqualThan:
      if (value >= filter.attributeValue) result = true;
      break;
    case StringOperator.LessThan:
      if (value < filter.attributeValue) result = true;
      break;
    case StringOperator.LessEqualThan:
      if (value <= filter.attributeValue) result = true;
      break;
    case StringOperator.BeginsWith:
      if (value.startsWith(filter.attributeValue)) result = true;
      break;
    case StringOperator.Contains:
      if (value.indexOf(filter.attributeValue) >= 0) result = true;
      break;
    case StringOperator.Exists:
      if (!!value) result = true;
      break;
    case StringOperator.NotContains:
      if (value.indexOf(filter.attributeValue) === -1) result = true;
      break;

    // todo - between
  }
  return result;
};

export const getSavedQueries = (modelId: string): SavedQuery[] => {
  const rawQueriesInLocalStorage: string = localStorage.getItem(`${modelId}-queries`) || "[]";
  const queries: SavedQuery[] = JSON.parse(rawQueriesInLocalStorage);
  return queries;
};

export const saveQuery = (modelId: string, query: SavedQuery) => {
  const savedQueries = getSavedQueries(modelId);
  localStorage.setItem(
    `${modelId}-queries`,
    JSON.stringify([...savedQueries.filter((q) => q.name !== query.name), query]),
  );
};

export const removeQuery = (modelId: string, queryName: string) => {
  const savedQueries = getSavedQueries(modelId);
  const filteredQueries = savedQueries.filter((query) => query.name !== queryName);
  localStorage.setItem(`${modelId}-queries`, JSON.stringify(filteredQueries));
};

export const clearQueries = (modelId: string) => {
  localStorage.setItem(`${modelId}-queries`, "[]");
};
