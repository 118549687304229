export const singleTableDesignerIntoSteps = [
  {
    selector: "#toolbar",
    content:
      "Single-table designer allows you to easily define your DynamoDB models. This is toolbar, it contains buttons for all the actions to slice and dice your model.",
  },
  {
    selector: "#table",
    content:
      "This is your table space visualization. Click on any cell to modify it.",
  },
  {
    selector: "#add-partition-row",
    content:
      "With + button on the partition key, you can add an item into selected partition.",
  },
  {
    selector: "#add-partition-row",
    content:
      "And with - button on the partition key, you remove a whole partition.",
  },
  {
    selector: "#remove-item",
    content:
      "With - button on the sort key, you can remove items from the model.",
  },
  {
    selector: ".ddb-type-picker",
    content:
      "Boxes on the left of every cell indicate attribute type. You can change it on non-indexed attributes.",
  },
  {
    selector: "#tableView",
    content: "Toggle between views to see your model in different ways.",
  },
  {
    selector: "#export",
    content:
      "Once ready, export your model to NoSQL Workbench, dynamodb-toolbox or dynamodb-onetable format",
  },
];
