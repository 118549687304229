/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  DocumentSearchIcon,
  RewindIcon,
  TrashIcon,
  ViewGridAddIcon,
  TableIcon,
  KeyIcon,
  CubeTransparentIcon,
  FilterIcon,
  CogIcon,
  CloudUploadIcon,
  CollectionIcon,
  CodeIcon,
} from "@heroicons/react/outline";
import { Box, Flex } from "rebass";
import { useFilePicker } from "use-file-picker";
import { Index, State, Views } from "./App";
import { importModel } from "./Importer";
import { QueryBuilderState } from "./QueryBuilder";
import { resetLastSavedState } from "./helpers/getInitialState";
import { ToolbarIcon, ToolbarIconProps } from "./ToolbarIcon";
import toast from "react-hot-toast";
import { Popover } from "@headlessui/react";
import { ExportAsPopover } from "./ExportAsPopover";
import { clearQueries } from "./Query";

export type ToolbarProps = {
  modelId: string;
  indexes: Index[];
  undoState: () => void;
  isUndoActive: boolean;
  setState: (state: State) => void;
  onActiveIndexChange: (index: Index) => void;
  removeGSI: (index: Index) => void;
  setIsGSIModalVisible: (isVisible: boolean) => void;
  setIsSettingsModalVisible: (isVisible: boolean) => void;
  setIsQueryBuilderVisible: any;
  isQueryBuilderVisible: boolean;
  setQueryState: (state: QueryBuilderState) => void;
  areOtherKeysVisible: boolean;
  setAreOtherKeysVisible: (areOtherKeysVisible: boolean) => void;
  isExcludedColumnsMenuVisible: boolean;
  setExcludedColumnsMenuVisible: (
    isExcludedColumnsMenuVisible: boolean
  ) => void;
  excludedColumnsCount: number;
  onExport: (...args: any[]) => void;
  onOnetableExport: (...args: any[]) => void;
  onDynamoDBToolboxModelExport: (...args: any[]) => void;
  setEmptyState: (emptyState: boolean) => void;
  deployTable: () => void;
  pkName: string;
  skName: string;
  entityTypeAttributeName: string;
  setCurrentView: (view: Views) => void;
  currentView: Views;
  isReadOnly?: boolean;
};

export const Toolbar = ({
  indexes,
  undoState,
  isUndoActive,
  onActiveIndexChange,
  setIsGSIModalVisible,
  setIsQueryBuilderVisible,
  isQueryBuilderVisible,
  setState,
  setQueryState,
  modelId,
  areOtherKeysVisible,
  setAreOtherKeysVisible,
  isExcludedColumnsMenuVisible,
  setExcludedColumnsMenuVisible,
  setIsSettingsModalVisible,
  excludedColumnsCount,
  onExport,
  setEmptyState,
  deployTable,
  onOnetableExport,
  onDynamoDBToolboxModelExport,
  pkName,
  skName,
  entityTypeAttributeName,
  setCurrentView,
  currentView,
  isReadOnly,
}: ToolbarProps) => {
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    multiple: false,
    accept: [".json"],
  });

  if (filesContent && filesContent.length > 0) {
    try {
      const ddbModel = JSON.parse(filesContent[0].content);
      const model = importModel(ddbModel);
      setState(model!);
      setEmptyState(false);
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    } finally {
      clear();
    }
  }

  const toolbarIcons: (
    | ToolbarIconProps
    | { type: "spacer"; hideInReadOnlyMode?: boolean }
  )[] = [
    {
      id: "showHideKeys",
      description: "Show/Hide non-currently selected index attributes",
      Icon: KeyIcon,
      onClick: () => setAreOtherKeysVisible(!areOtherKeysVisible),
      color: areOtherKeysVisible ? "black" : "gray",
      place: "right",
      type: "icon",
    },
    {
      id: "attributesExclude",
      description: "Exclude/Include attributes from the view",
      Icon: FilterIcon,
      onClick: () =>
        setExcludedColumnsMenuVisible(!isExcludedColumnsMenuVisible),
      afterPseudoElementStyle: excludedColumnsCount && {
        content: "2",
        color: "white",
        backgroundColor: "#4997f7",
        borderRadius: "50%",
        width: "8px",
        height: "8px",
        position: "absolute",
        right: "2px",
        bottom: "2px",
      },
      place: "right",
      type: "icon",
      hideInReadOnlyMode: true,
    },
    {
      type: "spacer",
    },
    {
      id: "tableView",
      description: "Table View",
      onClick: () => setCurrentView("table"),
      color: currentView === "table" ? "black" : "gray",
      Icon: TableIcon,
      place: "right",
      type: "icon",
    },
    {
      id: "attributesView",
      description: "Attributes View",
      onClick: () => setCurrentView("attributes"),
      color: currentView === "attributes" ? "black" : "gray",
      Icon: CollectionIcon,
      place: "right",
      type: "icon",
    },
    {
      id: "blobView",
      description: "Raw JSON view",
      onClick: () => setCurrentView("json"),
      color: currentView === "json" ? "black" : "gray",
      Icon: CodeIcon,
      place: "right",
      type: "icon",
    },
    {
      id: "modelsView",
      onClick: () => setCurrentView("models"),
      color: currentView === "models" ? "black" : "gray",
      Icon: CubeTransparentIcon,
      description: "Models View (Read Only)",
      type: "icon",
    },
    {
      type: "spacer",
      hideInReadOnlyMode: true,
    },
    {
      id: "addGsi",
      onClick: () => setIsGSIModalVisible(true),
      description: "Add new Global Secondary Index (GSI)",
      Icon: ViewGridAddIcon,
      type: "icon",
      hideInReadOnlyMode: true,
    },
    {
      id: "undo",
      description: "Undo Operation",
      onClick: () => undoState(),
      Icon: RewindIcon,
      color: isUndoActive ? "black" : "gray",
      fill: isUndoActive ? "black" : "gray",
      boxStyle: { cursor: isUndoActive ? "pointer" : "not-allowed" },
      type: "icon",
      hideInReadOnlyMode: true,
    },

    {
      id: "show-hide-query-builder",
      description: "Show/Hide Query Builder",
      color: isQueryBuilderVisible ? "black" : "gray",
      onClick: () => setIsQueryBuilderVisible((s: boolean) => !s),
      Icon: DocumentSearchIcon,
      type: "icon",
      hideInReadOnlyMode: true,
    },
    {
      type: "spacer",
    },
    {
      id: "deploy",
      description: "Deploy to AWS",
      onClick: () => deployTable(),
      Icon: CloudUploadIcon,
      type: "icon",
      hideInReadOnlyMode: true,
    },
    {
      id: "export",
      description: "Export Model",
      onClick: () => onExport(),
      Icon: ArrowCircleUpIcon,
      component: () => (
        <Popover
          style={{
            position: "relative",
          }}
        >
          <Popover.Button
            as="div"
            style={{ background: "transparent", border: 0, padding: 0 }}
          >
            <Box sx={{ cursor: "pointer" }} p={2} id="export">
              <Box
                className="toolbar-icon"
                p={2}
                style={{ position: "relative" }}
              >
                <ArrowCircleUpIcon width={16} height={16} />
              </Box>
            </Box>
          </Popover.Button>
          <ExportAsPopover
            onNoSQLWorkbenchModelExport={onExport}
            onOnetableModelExport={onOnetableExport}
            onDynamoDBToolboxModelExport={onDynamoDBToolboxModelExport}
          />
        </Popover>
      ),
      type: "icon",
    },
    {
      id: "import",
      description: "Import Model",
      onClick: () => openFileSelector(),
      Icon: ArrowCircleDownIcon,
      type: "icon",
      hideInReadOnlyMode: true,
    },
    {
      id: "resetModel",
      description: "⚠️ Reset Model - This will remove all your progress! ⚠️",
      Icon: TrashIcon,
      onClick: () => {
        resetLastSavedState();
        clearQueries(modelId);
        setEmptyState(true);
      },
      type: "icon",
      hideInReadOnlyMode: true,
    },
    {
      id: "settings",
      description: "Settings",
      Icon: CogIcon,
      onClick: () => setIsSettingsModalVisible(true),
      type: "icon",
      hideInReadOnlyMode: true,
    },
  ];

  const renderableToolbarIcons = toolbarIcons.filter(
    (icon) => !isReadOnly || (isReadOnly && !icon.hideInReadOnlyMode)
  );

  return (
    <Flex id="toolbar" className="toolbar">
      {renderableToolbarIcons.map((icon, index) => {
        if (icon.type === "spacer") {
          return (
            <div
              key={index}
              style={{
                width: "1px",
                height: "36px",
                margin: "6px",
                backgroundColor: "#BBB",
              }}
            ></div>
          );
        } else {
          return (
            <ToolbarIcon
              key={index}
              {...(icon as ToolbarIconProps)}
              id={(icon as any).id}
            />
          );
        }
      })}
      {isReadOnly && (
        <div style={{ position: "absolute", right: 10, top: 10 }}>
          <Flex
            sx={{
              color: "#555",
              fontSize: "12px",
            }}
            alignItems={"center"}
            justifyContent="center"
            flexDirection="column"
          >
            <p style={{ margin: 0 }}>Read-only mode</p>
            <p style={{ margin: 0 }}>
              <a
                href="https://dynobase.dev/download?ref=viewer"
                rel="noreferrer"
                target="_blank"
              >
                Download Dynobase
              </a>{" "}
              to edit it
            </p>
          </Flex>
        </div>
      )}
    </Flex>
  );
};
