import { useEffect, useRef } from "react";

export default function useComponentVisible(onHide: () => void) {
  const ref = useRef(null);
  const btnRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event: any) => {
    if (
      (ref.current && !(ref.current as any).contains(event.target)) ||
      (btnRef.current && (btnRef.current as any).contains(event.target))
    ) {
      onHide();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref, handleClickOutside, btnRef]);

  return { ref, btnRef };
}
