import { State, Type } from "../App";

export type ModelAttribute = {
  type: Type;
  default: string;
  template?: string;
};

export type ModelAttributeWithReferenceInfo = ModelAttribute & {
  reference?: {
    from: string;
    position: number;
  };
};

export type Model = {
  attributes: Record<string, ModelAttribute>;
  type: string;
};

export type ModelWithReferenceInfo = {
  attributes: Record<string, ModelAttributeWithReferenceInfo>;
  type: string;
};

export const computeModelsFromState = (state: State, entityTypeAttributeName: string): Model[] => {
  const models: Model[] = [];

  state.data.forEach((item) => {
    const foundModel = models.find(
      (m) => item[entityTypeAttributeName] && m.type === item[entityTypeAttributeName].value,
    );

    if (!foundModel) {
      const attributes: Record<string, ModelAttribute> = {};

      Object.entries(item).forEach(([key, attribute]) => {
        if (key.startsWith("__")) {
          return;
        }

        attributes[key] = {
          type: attribute.type,
          default: "~new~",
          template: attribute.template,
        };
      });

      if (item[entityTypeAttributeName]) {
        models.push({
          type: item[entityTypeAttributeName].value as string,
          attributes,
        });
      }
    }
  });

  return models;
};

export const enrichModelWithReferentialInfo = (model: Model): ModelWithReferenceInfo => {
  const newModel = JSON.parse(JSON.stringify(model));
  Object.entries(model.attributes).forEach(([key, value]) => {
    if (value.template) {
      const parts = value.template.split("#"); // part is in ${something} format
      const correctParts = parts.filter((p) => p.match(/^\$\{([a-zA-Z0-9])+\}/));
      const formattedParts = correctParts.map((p) => p.replace(/\$\{|\}/g, ""));

      formattedParts.forEach((part, index) => {
        newModel.attributes[part] = {
          ...newModel.attributes[part],
          reference: {
            from: key,
            position: index,
          },
        };
      });
    }
  });
  return newModel;
};
