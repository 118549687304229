import { SyntheticEvent } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { Cell } from "react-table";
import { ContextMenuProps } from "./ContextMenu";

type PartitionKeyContextMenuProps = ContextMenuProps & {
  removePartition: ({ cell }: { cell: Cell }) => void;
  addPartitionRow: ({ cell }: { cell: Cell }) => void;
  addPartition: () => void;
};

const PartitionKeyContextMenu = ({
  updateMyData,
  addPartition,
  removePartition,
  addPartitionRow,
}: PartitionKeyContextMenuProps) => (
  <ContextMenu id="partition-key-menu">
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        addPartition();
      }}
    >
      Add Partition
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        addPartitionRow({ cell });
      }}
    >
      Add Item
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        removePartition({ cell });
      }}
    >
      Delete Partition
    </MenuItem>
  </ContextMenu>
);

export default PartitionKeyContextMenu;
