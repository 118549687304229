import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { Text, Flex, Box } from "rebass";
import { groupBy } from "lodash";
import ReactTooltip from "rc-tooltip";
import { Popover } from "@headlessui/react";
import { getSavedQueries, removeQuery, SavedQuery } from "./Query";
import { QueryBuilderState } from "./QueryBuilder";

type SidebarProps = {
  modelId: string;
  setHighlightedAccessPattern: (accessPattern?: SavedQuery) => void;
  setQueryState: (state: QueryBuilderState) => void;
  setIsQueryBuilderVisible: (isQueryBuilderVisible: boolean) => void;
  isQueryBuilderVisible: boolean;
  changeActiveIndexByName: (name: string) => void;
  readOnly?: boolean;
};

export const Sidebar = ({
  modelId,
  isQueryBuilderVisible,
  setHighlightedAccessPattern,
  setQueryState,
  setIsQueryBuilderVisible,
  changeActiveIndexByName,
  readOnly,
}: SidebarProps) => {
  const savedQueries = getSavedQueries(modelId);
  const groupedQueries = groupBy(savedQueries, (query) => query.currentIndex);

  if (!isQueryBuilderVisible) {
    return <div></div>;
  }

  return (
    <div className="single-table-sidepanel">
      <Text p={"16px 16px 17px"} bg="#f5f5f5" sx={{ borderBottom: "1px solid #ccc" }}>
        Access Patterns
      </Text>

      {savedQueries.length === 0 && <NoQueriesComponent />}

      <Box p={3}>
        {Object.entries(groupedQueries).map(([name, queries]) => (
          <>
            <Flex my={1} sx={{ cursor: "pointer" }}>
              <ChevronDownIcon
                width={12}
                height={12}
                style={{
                  marginTop: "3px",
                  marginRight: "4px",
                  marginLeft: "-6px",
                }}
              ></ChevronDownIcon>{" "}
              <Text mb={1} color="#555" fontSize={"14px"}>
                {name} index
              </Text>
            </Flex>
            {queries.map((query) => (
              <AccessPatternItem
                changeActiveIndexByName={changeActiveIndexByName}
                setQueryState={setQueryState}
                query={query}
                modelId={modelId}
                setIsQueryBuilderVisible={setIsQueryBuilderVisible}
                setHighlightedAccessPattern={setHighlightedAccessPattern}
                readOnly={readOnly}
              />
            ))}
          </>
        ))}
      </Box>
    </div>
  );
};
const AccessPatternItem = ({
  query,
  modelId,
  setHighlightedAccessPattern,
  setQueryState,
  setIsQueryBuilderVisible,
  changeActiveIndexByName,
  readOnly,
}: {
  query: SavedQuery;
  modelId: string;
  setHighlightedAccessPattern: (query: SavedQuery | undefined) => void;
  setQueryState: (state: QueryBuilderState) => void;
  setIsQueryBuilderVisible: (isQueryBuilderVisible: boolean) => void;
  changeActiveIndexByName: (name: string) => void;
  readOnly?: boolean;
}) => (
  <ReactTooltip
    id={`accessPattern-${query.name}`}
    mouseEnterDelay={1}
    mouseLeaveDelay={0}
    placement={"top"}
    overlay={<span>Click to load access pattern</span>}
  >
    <div
      className="access-pattern-item"
      onMouseLeave={() => {
        setHighlightedAccessPattern(undefined);
        // changeActiveIndexByName("primary");
      }}
      onMouseEnter={() => {
        setHighlightedAccessPattern(query);
        // changeActiveIndexByName(query.currentIndex);
      }}
      onClick={() => {
        setQueryState(query);
        setIsQueryBuilderVisible(true);
        changeActiveIndexByName(query.currentIndex);
      }}
    >
      {query.name}
      {!readOnly && (
        <Popover
          style={{
            position: "relative",
            float: "right",
          }}
        >
          <Popover.Button as="div" style={{ background: "transparent", border: 0, padding: 0 }}>
            <TrashIcon width={12} height={12} color={"gray"} />
          </Popover.Button>
          <Popover.Panel
            style={{
              background: "white",
              boxShadow: "5px 5px 10px #ddd",
              position: "absolute",
              padding: "8px",
              border: "1px solid #d5d5d5",
              minWidth: "120px",
              zIndex: 10000,
            }}
          >
            <Text fontSize="10px">Are you sure you want to delete this query?</Text>
            <Text
              onClick={() => removeQuery(modelId, query.name)}
              style={{
                textDecoration: "underline",
                textDecorationColor: "black",
              }}
            >
              Yes
            </Text>
          </Popover.Panel>
        </Popover>
      )}
    </div>
  </ReactTooltip>
);

const NoQueriesComponent = () => (
  <Flex flexDirection="column">
    <Text
      sx={{
        padding: "10px",
        marginTop: "140px",
        textAlign: "center",
        color: "gray",
      }}
    >
      No access patterns defined
    </Text>
    <Text
      sx={{
        padding: "10px",
        marginTop: "140px",
        textAlign: "center",
      }}
    ></Text>
  </Flex>
);
