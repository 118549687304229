import React, { useEffect, useState } from "react";
import { dataToItem, itemToData } from "dynamo-converters";
import ModalBase from "./ModalBase";
import Button from "./Button";
import { SaveAsIcon, SparklesIcon } from "@heroicons/react/outline";
import { Flex } from "rebass";
import { Checkbox, Label } from "@rebass/forms";
import Monaco from "react-monaco-editor";
import { convertFromDDBJSON } from "./helpers/convertFromDDBJSON";

type EditAttributesModalProps = {
  isVisible: boolean;
  initialValue: string;
  onClose: () => void;
  saveAttributes: (attributes: any) => void;
  ddbJson: boolean;
  readOnly?: boolean;
};

const EditAttributesModal = (props: EditAttributesModalProps) => {
  const [state, setState] = useState<string>(props.initialValue);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isUsingDynamoDBJSON, setIsUsingDynamoDBJSON] = useState(true);

  useEffect(() => {
    setState(props.initialValue);
  }, [props.initialValue]);

  return (
    <ModalBase
      onClose={props.onClose}
      modalTitle={"Other Attributes"}
      isVisible={props.isVisible}
      content={() => (
        <div style={{ width: "100%" }}>
          <Monaco
            height="90vh"
            width="100%"
            options={{
              automaticLayout: true,
              minimap: { enabled: false },
            }}
            language="json"
            defaultValue={state}
            value={state}
            onChange={(e) => {
              setState(e!);

              try {
                JSON.parse(e!);
              } catch (error) {
                setError("Failed to parse JSON!");
                return;
              }

              setError(undefined);

              if (props.ddbJson) {
                try {
                  convertFromDDBJSON(isUsingDynamoDBJSON ? JSON.parse(e!) : dataToItem(JSON.parse(e!)));
                } catch (error: any) {
                  setError(error.message);
                  return;
                }
              }

              setError(undefined);
            }}
          />
        </div>
      )}
      footer={() => (
        <>
          {!error && !props.readOnly && (
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ borderTop: "1px solid #e4e4e4" }}
              p={3}
            >
              <Button
                disabled={!!error}
                icon={() => (
                  <SaveAsIcon
                    width={16}
                    height={16}
                    color={"#fff"}
                    style={{
                      marginBottom: -3,
                    }}
                  />
                )}
                className="button-save-item"
                style={{
                  padding: "10px",
                  backgroundColor: !!error ? "gray" : "#4997f7",
                  marginLeft: "20px",
                  color: "white",
                  marginRight: "10px",
                }}
                color="white"
                onClick={() => {
                  props.saveAttributes(JSON.parse(state));
                  props.onClose();
                }}
              >
                Save
              </Button>
              <Button
                disabled={!!error}
                icon={() => (
                  <SparklesIcon
                    width={16}
                    height={16}
                    color={"#fff"}
                    style={{
                      marginBottom: -3,
                    }}
                  />
                )}
                className="button-prettify-json"
                style={{
                  padding: "10px",
                  backgroundColor: !!error ? "gray" : "#4997f7",
                  marginLeft: "4px",
                  color: "white",
                  marginRight: "10px",
                }}
                color="white"
                onClick={() => {
                  setState((state) => JSON.stringify(JSON.parse(state), null, 2));
                }}
              >
                Format JSON
              </Button>
              <Label width="36px" key={"dynamodb-json-toggle"} px={2} style={{ cursor: "pointer" }}>
                <Checkbox
                  id="dynamodb-json-toggle"
                  className="rebass-checkbox"
                  onChange={() =>
                    setIsUsingDynamoDBJSON((prev) => {
                      if (prev) {
                        setState(JSON.stringify(itemToData(JSON.parse(state)), null, 2));
                      } else {
                        setState(JSON.stringify(dataToItem(JSON.parse(state)), null, 2));
                      }

                      return !prev;
                    })
                  }
                  checked={isUsingDynamoDBJSON}
                />
              </Label>
              Use DynamoDB JSON syntax
            </Flex>
          )}
          {error && (
            <Flex p={3} style={{ borderTop: "1px solid #e4e4e4" }}>
              <div style={{ color: "red" }}>{error || ""}</div>
            </Flex>
          )}
        </>
      )}
    />
  );
};

export default EditAttributesModal;
