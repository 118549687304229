import { XIcon } from "@heroicons/react/outline";
import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { Box, Flex } from "rebass";

interface ModalBaseProps {
  modalTitle: string;
  content: () => JSX.Element;
  footer: () => JSX.Element;
  isVisible: boolean;
  noContentOverflow?: boolean;
  onClose: () => void;
  windowProps?: any;
  headerProps?: any;
  className?: string;
}

const ModalBase = (props: ModalBaseProps) => {
  useEffect(() => {
    const escHandler = (e: any) => {
      if (e.key === "Escape" && props.isVisible) {
        props.onClose();
      }
    };

    window.addEventListener("keyup", escHandler);

    return () => {
      window.removeEventListener("keydown", escHandler);
    };
    // eslint-disable-next-line
  }, [props.isVisible]);

  return (
    <ReactModal className={props.className} isOpen={props.isVisible}>
      <Box
        style={{
          ...props.windowProps,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          style={{ borderBottom: "1px solid #e5e5e5" }}
          px={3}
          py={2}
        >
          <h2
            style={{
              ...props.headerProps,
              marginBottom: 0,
              marginTop: "5px",
              fontSize: "1em",
            }}
          >
            {props.modalTitle}
          </h2>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => props.onClose()}
          >
            <XIcon style={{ marginBottom: -2 }} width={16} height={16} />
          </div>
        </Flex>
        <Box
          style={
            props.noContentOverflow ? {} : { overflowY: "scroll", flexGrow: 1 }
          }
        >
          {props.content()}
        </Box>
        {props.footer()}
      </Box>
    </ReactModal>
  );
};

export default ModalBase;
