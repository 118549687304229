import { Attribute } from "../App";
import {
  DynamoDBBoolean,
  DynamoDBNumber,
  DynamoDBString,
} from "../types/Model";

export function convertFromDDBJSON(
  ddbItem: Record<string, DynamoDBBoolean | DynamoDBNumber | DynamoDBString>
) {
  const item: Record<string, Attribute> = {};

  Object.entries(ddbItem).forEach(([key, value]) => {
    const [ddbType, ddbValue] = Object.entries(value)[0];

    if (!["S", "N", "BOOL", "SS", "NS", "NULL", "L", "M"].includes(ddbType)) {
      throw new Error(
        `Unsupported DynamoDB type "${ddbType}" on attribute "${key}".`
      );
    }

    item[key] = {
      type: convertFromDDBType(ddbType as any),
      value: ddbValue,
    };
  });

  return item;
}

export function convertFromDDBType(
  type: "S" | "N" | "BOOL" | "M" | "NULL"
): "string" | "template" | "number" | "boolean" | "null" | "map" {
  if (type === "S") return "string";
  if (type === "N") return "number";
  if (type === "NULL") return "null";
  if (type === "M") return "map";
  if (type === "BOOL") return "boolean";
  if (type === null) return "null";
  return "string";
}
