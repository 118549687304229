import {
  ArrowCircleDownIcon,
  FolderOpenIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { Flex, Text } from "rebass";
import { useFilePicker } from "use-file-picker";
import { getLastSavedState, initialState } from "./helpers/getInitialState";
import { importModel } from "./Importer";
import ACLModel from "./examples/ACL.json";
import BankingModel from "./examples/Banking.json";
import FlightFares from "./examples/FlightFares.json";
import Hospital from "./examples/Hospital.json";
import HotelReservations from "./examples/HotelReservations.json";
import { useEffect, useState } from "react";
import NewModelModal from "./NewModelModal";
import { asyncSetItem } from "./helpers/asyncSetItem";

type EmptyStateScreenProps = {
  disableEmptyState: () => void;
  onImport: () => void;
  setState: (arg: any) => void;
  setEntityTypeAttributeName: (name: string) => void;
};

const exampleModels = [
  {
    name: "ACL.json",
    description: "A simple Access Control List model",
    model: ACLModel,
  },
  {
    name: "Banking.json",
    description: "Accounts, Transactions, Customers & Purchases",
    model: BankingModel,
  },
  {
    name: "FlightFares.json",
    description: "Simple Airport Scheduling system - Flights, Fares, Bookings",
    model: FlightFares,
  },
  {
    name: "Hospital.json",
    description: "Doctors, Rooms, Activities, Patients, Activities & more",
    model: Hospital,
  },
  {
    name: "HotelReservations.json",
    description: "Bookings, Rates, Customers and so on",
    model: HotelReservations,
  },
];

const EmptyStateScreen = ({
  disableEmptyState,
  setState,
  setEntityTypeAttributeName,
}: EmptyStateScreenProps) => {
  const lastState = getLastSavedState();
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    multiple: false,
    accept: [".json"],
  });
  const [isNewModelOpen, setIsNewModelOpen] = useState(false);

  if (filesContent && filesContent.length > 0) {
    try {
      const ddbModel = JSON.parse(filesContent[0].content);
      const model = importModel(ddbModel);
      setState(model);
      disableEmptyState();
    } catch (error) {
      console.error(error);
    } finally {
      clear();
    }
  }

  useEffect(() => {
    if (lastState) {
      disableEmptyState();
      setState(lastState);
    }
  }, [disableEmptyState, lastState, setState]);

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      <Flex alignItems="center" justifyContent="center" pt={4} mb={4}>
        <Flex
          flexDirection="column"
          px={5}
          py={4}
          bg="#f5f5f5"
          m={2}
          sx={{ borderRadius: "8px", cursor: "pointer" }}
          onClick={() => {
            setIsNewModelOpen(true);
          }}
        >
          <PlusCircleIcon height="40px" color="#ccc"></PlusCircleIcon>
          <Text mt={1}>New Model</Text>
        </Flex>
        <Flex
          flexDirection="column"
          px={5}
          py={4}
          bg="#f5f5f5"
          m={2}
          sx={{ borderRadius: "8px", cursor: "pointer" }}
          onClick={() => {
            openFileSelector();
          }}
        >
          <ArrowCircleDownIcon height="40px" color="#ccc"></ArrowCircleDownIcon>
          <Text mt={1}>Import Model</Text>
        </Flex>
        {lastState && (
          <Flex
            flexDirection="column"
            px={5}
            py={4}
            bg="#f5f5f5"
            m={2}
            sx={{ borderRadius: "8px", cursor: "pointer" }}
            onClick={() => {
              disableEmptyState();
              setState(lastState);
            }}
          >
            <FolderOpenIcon height="40px" color="#ccc"></FolderOpenIcon>
            <Text mt={1}>Restore last model</Text>
          </Flex>
        )}
      </Flex>
      <Flex
        alignItems="start"
        justifyContent="start"
        pt={4}
        mb={4}
        flexDirection="column"
      >
        <Text fontSize="1.4em" my={2} mt={4} color="#6b6b6b">
          Example models
        </Text>
        {exampleModels.map((example) => (
          <Flex
            key={example.name}
            sx={{ cursor: "pointer", fontFamily: "sans-serif" }}
            onClick={() => {
              const newState = importModel(example.model as any);
              setState(newState);
              asyncSetItem("lastState", JSON.stringify(newState));
              disableEmptyState();
            }}
          >
            <Text
              sx={{
                textDecoration: "underline",
                fontFamily: "monospace",
                fontSize: "15px",
              }}
              mr={1}
              color="cornflowerblue"
            >
              {example.name}
            </Text>{" "}
            -{" "}
            <Text color="#555" ml={1}>
              {example.description}
            </Text>
          </Flex>
        ))}
      </Flex>
      <NewModelModal
        onClose={() => setIsNewModelOpen(false)}
        isVisible={isNewModelOpen}
        newModel={(model) => {
          disableEmptyState();
          setState(
            initialState(
              model.partitionKey,
              model.sortKey,
              model.entityTypeAttributeName,
              model.modelName
            )
          );
          setEntityTypeAttributeName(model.entityTypeAttributeName);
        }}
      />
    </Flex>
  );
};

export default EmptyStateScreen;
