import { Attribute } from "../App";

export function convertToDDBJSON(
  item: Record<string, Attribute>,
  keysToBeIncluded: string[] = [],
  keysToBeExcluded: string[] = []
) {
  const ddbItem: Record<string, any> = {};

  Object.entries(item).forEach(([key, value]) => {
    if (!keysToBeIncluded.includes(key) || keysToBeExcluded.includes(key)) {
      return;
    }

    if (value.template || value.value) {
      ddbItem[key] = {
        [convertToDDBType(value.type)]: value.template || value.value,
      };
    }
  });

  return ddbItem;
}

export function convertToDDBType(
  type: "string" | "number" | "template" | "boolean" | "null" | "map"
) {
  if (!type) {
    return "S";
  }

  const lowercasedType = type.toLowerCase();
  if (lowercasedType === "string") return "S";
  if (lowercasedType === "number") return "N";
  if (lowercasedType === "boolean") return "BOOL";
  if (lowercasedType === "map") return "M";
  if (lowercasedType === "null") return "NULL";
  return "S";
}
