import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { ReadOnly } from "./Readonly";

const saveFile = async (blob: any, name: string) => {
  const a = document.createElement("a");
  a.download = name;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

const loadFullVersion = window.location.pathname === "/full";

if (loadFullVersion) {
  ReactDOM.render(
    <React.StrictMode>
      <App
        onExport={(model) => {
          const blob = new Blob([JSON.stringify(model, null, 2)], { type: "application/json" });
          saveFile(blob, "model.json");
        }}
        onOnetableExport={(model) => {
          const blob = new Blob([JSON.stringify(model, null, 2)], { type: "application/json" });
          saveFile(blob, "onetable-model.json");
        }}
        onDynamoDBToolboxModelExport={(code) => {
          const blob = new Blob([code], { type: "application/json" });
          saveFile(blob, "dynamodb-toolbox-model.js");
        }}
        setNewTableInitialData={() => {}}
      />
    </React.StrictMode>,
    document.getElementById("root"),
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ReadOnly
        onExport={(model) => {
          const blob = new Blob([JSON.stringify(model, null, 2)], { type: "application/json" });
          saveFile(blob, "model.json");
        }}
        onOnetableExport={(model) => {
          const blob = new Blob([JSON.stringify(model, null, 2)], { type: "application/json" });
          saveFile(blob, "onetable-model.json");
        }}
        onDynamoDBToolboxModelExport={(code) => {
          const blob = new Blob([code], { type: "application/json" });
          saveFile(blob, "dynamodb-toolbox-model.js");
        }}
        setNewTableInitialData={() => {}}
      />
    </React.StrictMode>,
    document.getElementById("root"),
  );
}
