import { v4 } from "uuid";
import { State } from "../App";

export const initialState = (
  pkName: string,
  skName: string,
  entityTypeName: string,
  modelName: string
): State => ({
  modelName: modelName,
  data: [
    {
      [pkName]: {
        value: "~new~",
        template: "",
        type: "string",
      },
      [skName]: {
        value: "~new~",
        template: "",
        type: "string",
      },
      [entityTypeName]: {
        value: "~new~",
        template: "",
        type: "string",
      },
      __id: v4() as any,
    },
  ],
  indexes: [
    {
      name: "primary",
      sort: skName,
      hash: pkName,
      isSelected: true,
    },
  ],
  excludedColumns: [],
});

export const getInitialState = (): State => {
  const lastSavedState = window.localStorage.getItem("lastState");
  if (lastSavedState) {
    return JSON.parse(lastSavedState);
  }

  return initialState("pk", "sk", "type", "new-model");
};

export const getLastSavedState = (): State | undefined => {
  const lastSavedState = window.localStorage.getItem("lastState");
  if (lastSavedState) {
    return JSON.parse(lastSavedState);
  }
};

export const resetLastSavedState = () => {
  window.localStorage.removeItem("lastState");
};
