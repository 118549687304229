import { SyntheticEvent } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { Cell, Row } from "react-table";
import { ContextMenuProps } from "./ContextMenu";
import GenerateValueSubMenu from "./GenerateValueSubMenu";

type SortKeyContextMenuProps = ContextMenuProps & {
  removeSk: ({ row }: { row: Row }) => void;
  duplicateRow: ({ cell }: { cell: Cell }) => void;
};

const SortKeyContextMenu = ({
  updateMyData,
  duplicateRow,
  removeSk,
}: SortKeyContextMenuProps) => (
  <ContextMenu id="sort-key-menu">
    <MenuItem
      onClick={(e: SyntheticEvent, cell: Cell) => {
        navigator.clipboard.writeText(JSON.stringify(cell.row.values, null, 2));
      }}
    >
      Copy Item
    </MenuItem>
    <MenuItem
      onClick={(e: SyntheticEvent, cell: Cell) => {
        duplicateRow({ cell });
      }}
    >
      Duplicate Item
    </MenuItem>
    <MenuItem
      onClick={(e: SyntheticEvent, cell: Cell) => {
        removeSk({ row: cell.row });
      }}
    >
      Delete Item
    </MenuItem>
    <GenerateValueSubMenu updateMyData={updateMyData} />
  </ContextMenu>
);

export default SortKeyContextMenu;
