/* eslint-disable jsx-a11y/anchor-is-valid,react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ReactJson from "react-json-view";
import { IndexProjection, PrimaryIndex } from "./types/Model";
import { Box, Text } from "rebass";
import { ErrorBoundary } from "react-error-boundary";
import { Table } from "./Table";
import toast, { Toaster } from "react-hot-toast";
import { Cell, Row } from "react-table";
import AttributeContextMenu from "./AttributeContextMenu";
import SortKeyContextMenu from "./SortKeyContextMenu";
import PartitionKeyContextMenu from "./PartitionKeyContextMenu";
import NewGSIModal from "./NewGSIModal";
import GSIContextMenu from "./GSIContextMenu";
import { Toolbar } from "./Toolbar";
import QueryBuilder, { QueryBuilderState } from "./QueryBuilder";
import { exportModel } from "./Exporter";
import Button from "./Button";
import { AttributeType, StringOperator } from "./FilterExpression";
import { evaluateRowAgainstFilters, SavedQuery } from "./Query";
import { useForceUpdate } from "./hooks/useForceUpdate";
import { partitionKeyColumn } from "./columns/PartitionKeyColumn";
import EditAttributesModal from "./AttributesEditorModal";
import { sortKeyColumn } from "./columns/SortKeyColumn";
import { otherIndexColumn } from "./columns/OtherIndexColumn";
import { NewColumn } from "./columns/NewColumn";
import { otherAttributesColumn } from "./columns/OtherAttributesColumn";
import { attributesColumn } from "./columns/AttributesColumn";
import { asyncSetItem } from "./helpers/asyncSetItem";
import { getInitialState } from "./helpers/getInitialState";
import ExcludedAttributesMenu from "./ExcludedAttributesPopover";
import useComponentVisible from "./hooks/useComponentVisible";
import { TableIcon } from "@heroicons/react/outline";
import { ModelsTable } from "./ModelsTable";
import { modelColumns } from "./columns/ModelColumns";
import { computeModelsFromState } from "./helpers/computeModelsFromState";
import SettingsModal from "./SettingsModal";
import { getSettings } from "./helpers/settings";
import "./index.css";
import EmptyStateScreen from "./EmptyStateScreen";
import { convertFromDDBJSON } from "./helpers/convertFromDDBJSON";
import { OtherAttributesListColumn } from "./columns/ListAttributesColumn";
import { v4 } from "uuid";
import { generateDynamoDBToolboxCode } from "./helpers/generateDynamoDBToolboxCode";
import { Sidebar } from "./Sidebar";

const HISTORY_LENGTH = 50;

export type Type =
  | "string"
  | "template"
  | "number"
  | "boolean"
  | "null"
  | "map";

export type Views = "table" | "json" | "models" | "attributes";

export type Index = PrimaryIndex & {
  name: string;
  isSelected: boolean;
  projection?: IndexProjection;
  projectedAttributes?: string[];
};

export type Attribute = {
  value: unknown;
  template?: string;
  type: Type; // Template is actually a string but in a format {someOtherAttribute}#{someOtherAttribute}
};

export type State = {
  modelName: string;
  data: Record<string, Attribute>[];
  indexes: Index[];
  excludedColumns: string[];
};

export type AppProps = {
  onExport: (exportedModel: any) => void;
  onOnetableExport: (exportedModel: any) => void;
  onDynamoDBToolboxModelExport: (exportedCode: string) => void;
  setNewTableInitialData: (data: any) => void;
  readOnly?: boolean;
  initialState?: State;
};

const App = ({
  onExport,
  onOnetableExport,
  onDynamoDBToolboxModelExport,
  setNewTableInitialData,
  readOnly,
  initialState,
}: AppProps) => {
  const { forceUpdate } = useForceUpdate();
  const [state, setState] = useState<State>(initialState || getInitialState());
  const [historyEntries, setHistoryEntries] = useState<State[]>([]);
  const [isEmptyState, setEmptyState] = useState(!initialState);
  const [isAddGSIModalVisible, setIsAddGSIModalVisible] = useState(false);
  const [isAddingNewColumn, setIsAddingNewColumn] = useState(false);
  const [isQueryBuilderVisible, setIsQueryBuilderVisible] = useState(false);
  const [areOtherKeysVisible, setAreOtherKeysVisible] = useState(true);
  const [entityTypeAttributeName, setEntityTypeAttributeName] =
    useState<string>("type");
  const [highlightedAccessPattern, setHighlightedAccessPattern] = useState<
    SavedQuery | undefined
  >(undefined);
  const [attributesModalState, setAttributesModalState] = useState({
    isOpen: false,
    rowIndex: -1,
    columnId: "",
    blob: "",
  });
  const [currentView, setCurrentView] = useState<Views>("attributes");
  const [mapAttributeModalState, setMapAttributesModalState] = useState({
    isOpen: false,
    rowIndex: -1,
    columnId: "",
    blob: "",
  });
  const [queryState, setQueryState] = useState<QueryBuilderState>({
    currentIndex: state.indexes[0].name,
    pkValue: "",
    skOperator: StringOperator.Equal,
    skValue: "",
    filterExpressions: [],
  });
  const [isExcludedColumnsMenuVisible, setExcludedColumnsMenuVisible] =
    useState<boolean>(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    useState<boolean>(false);
  const { ref } = useComponentVisible(() =>
    setExcludedColumnsMenuVisible(false)
  );
  const {
    attributesTruncateLength,
    entityTypeAttributePosition,
    showModelOutput,
  } = getSettings();
  const [tableStyle, setTableStyle] = useState<string>(
    localStorage.getItem("single-table-designer-style") || "monospace"
  );

  const addHistoryEntry = (state: State) => {
    setHistoryEntries((entries) => {
      const lastHistoryEntry = entries[entries.length - 1];
      // Add history entry only if it is not the same as the last one
      if (
        !lastHistoryEntry ||
        (lastHistoryEntry &&
          JSON.stringify(lastHistoryEntry) !== JSON.stringify(state))
      ) {
        entries?.push(state);
        // Trim tail to maintain history length of max 50
        if (entries.length > HISTORY_LENGTH) {
          entries.shift();
        }
      }

      return entries;
    });
  };

  const setData = (
    newData: (
      oldState: Record<string, Attribute>[]
    ) => Record<string, Attribute>[]
  ) => {
    setState((oldState) => {
      addHistoryEntry(oldState);
      const newState = {
        ...oldState,
        data: newData(oldState.data),
      };

      asyncSetItem("lastState", JSON.stringify(newState));

      return newState;
    });
  };

  const undoState = () => {
    if (historyEntries.length > 0) {
      setHistoryEntries((entries) => {
        const entry = entries.pop();
        console.log("Undo to", entry!);

        setState(entry!);
        return entries;
      });
    }
  };

  const changeActiveIndexByName = (indexName: string) => {
    setState((oldState) => ({
      ...oldState,
      indexes: [...state.indexes].map((i) => ({
        ...i,
        isSelected: indexName === i.name,
      })),
    }));
  };

  const changeActiveIndex = (index: Index) => {
    setState((oldState) => ({
      ...oldState,
      indexes: [...state.indexes].map((i) => ({
        ...i,
        isSelected: index.name === i.name,
      })),
    }));
  };

  const currentIndex =
    state.indexes.find((i) => i.isSelected) || state.indexes[0];

  const pkName = currentIndex.hash;
  const skName = currentIndex.sort;

  const nonPrimaryKeyAttributes = () => {
    const nonKeyAttributes: string[] = [];
    state.data.forEach((row: any) => {
      Object.keys(row).forEach((key) => {
        if (key !== pkName && key !== skName) {
          nonKeyAttributes.push(key);
        }
      });
    });

    return Array.from(new Set(nonKeyAttributes));
  };

  const nonAnyKeyAttributes = () => {
    const nonKeyAttributes: string[] = [entityTypeAttributeName];
    state.data.forEach((row: any) => {
      Object.keys(row).forEach((key) => {
        const relatedIndex = state.indexes.find(
          (i) => i.sort === key || i.hash === key
        );

        if (!relatedIndex && key !== "__id") {
          nonKeyAttributes.push(key);
        }
      });
    });

    return Array.from(new Set(nonKeyAttributes));
  };

  const nonPrimaryKeyAttributesList = nonPrimaryKeyAttributes();
  const nonAnyKeyAttributesList = nonAnyKeyAttributes();

  const getRenderableData = () => {
    if (!isQueryBuilderVisible || queryState.pkValue.length < 1) {
      return state.data
        .filter(
          (row) => row[pkName] && (row[pkName].value || row[pkName].template)
        )
        .map((row) => {
          const pkMatch =
            highlightedAccessPattern &&
            row[pkName] &&
            row[pkName].value === highlightedAccessPattern.pkValue;
          const shouldHighlight =
            highlightedAccessPattern &&
            evaluateRowAgainstFilters(row, [
              {
                id: "sortkey",
                attributeName: skName,
                attributeValue: highlightedAccessPattern.skValue,
                attributeType: AttributeType.String,
                operator: highlightedAccessPattern.skOperator,
                logicalEvaluation: "AND",
                createdAt: +new Date(),
              },
              ...highlightedAccessPattern.filterExpressions,
            ]) &&
            pkMatch;

          return {
            ...row,
            __highlighted: shouldHighlight,
            __highlightedPk: pkMatch,
          };
        });
    }

    const partitionFilteredData = state.data.filter(
      (row) => row[pkName] && row[pkName].value === queryState.pkValue
    );

    const filteredData = partitionFilteredData.filter((row) =>
      evaluateRowAgainstFilters(row, [
        {
          id: "sortkey",
          attributeName: skName,
          attributeValue: queryState.skValue,
          attributeType: AttributeType.String,
          operator: queryState.skOperator,
          logicalEvaluation: "AND",
          createdAt: +new Date(),
        },
        ...queryState.filterExpressions,
      ])
    );

    return filteredData;
  };

  const renderableData = getRenderableData();

  const addGSI = (index: Index) => {
    setState((oldState) => ({
      ...oldState,
      indexes: [...oldState.indexes, index],
    }));
  };

  const removeGSI = (index: Index) => {
    setState((oldState) => ({
      ...oldState,
      indexes: [...oldState.indexes.filter((i) => i.name !== index.name)],
    }));
  };

  const addAttributeToAllItems = (attribute: Attribute) => {
    setState((oldState) => ({
      ...oldState,
      data: oldState.data.map((row) => {
        return {
          ...row,
          [attribute.value as string]: {
            type: attribute.type,
            value: "",
          },
        };
      }),
    }));
  };

  // If modelName param is supplied, it will be removed only from occurrences of the model
  const removeAttributeFromAllItems = (
    attributeName: string,
    modelName?: string
  ) => {
    setData((oldData) =>
      oldData.map((row) => {
        const newRow = {
          ...row,
        } as any;

        if (!modelName || modelName === row[entityTypeAttributeName].value) {
          delete newRow[attributeName];
        }

        return newRow;
      })
    );
  };

  const addPartitionRow = ({
    cell,
    extraData = {},
  }: {
    cell: Cell;
    extraData?: Record<string, Attribute>;
  }) => {
    const partitionKey = cell.value;

    const newItem = {
      [pkName]: partitionKey,
      [skName]: "~new~",
      __id: v4() as any,
      ...extraData,
    };

    if (!newItem[state.indexes[0].hash]) {
      newItem[state.indexes[0].hash] = {
        type: AttributeType.String,
        value: "~new~",
      };
    }
    if (!newItem[state.indexes[0].sort]) {
      newItem[state.indexes[0].sort] = {
        type: AttributeType.String,
        value: "~new~",
      };
    }

    setData((oldData) => [...oldData, newItem].sort(sortByPartitions(pkName)));
  };

  const duplicateRow = ({ cell }: { cell: Cell }) => {
    const value = JSON.parse(JSON.stringify(cell.row.values));
    value.__id = v4();

    setData((oldData) => [...oldData, value]);
  };

  const deployTable = () => {
    const exportedModel = exportModel(state, entityTypeAttributeName);
    setNewTableInitialData({
      keyType: "Composite",
      keySchema: [
        {
          KeyType: "HASH",
          AttributeName: state.indexes[0].hash,
        },
        {
          KeyType: "RANGE",
          AttributeName: state.indexes[0].sort,
        },
      ],
      attributeDefinitions: [
        ...state.indexes.map((i) => ({
          AttributeName: i.hash,
          AttributeType: "S",
        })),
        ...state.indexes.map((i) => ({
          AttributeName: i.sort,
          AttributeType: "S",
        })),
      ],
      gsis: exportedModel.DataModel[0].GlobalSecondaryIndexes.map((gsi) => ({
        IndexName: gsi.IndexName,
        Projection: "ALL", // todo respect gsi setting
        KeySchema: [
          {
            AttributeName: gsi.KeyAttributes.PartitionKey.AttributeName,
            KeyType: "HASH",
          },
          {
            AttributeName: gsi.KeyAttributes.SortKey.AttributeName,
            KeyType: "RANGE",
          },
        ],
      })),
    });
  };

  const excludeColumn = (columnId: string) => {
    setState((s) => ({
      ...s,
      excludedColumns: [...s.excludedColumns, columnId],
    }));
  };

  const addPartition = () => {
    setData((oldData) => [
      ...oldData,
      {
        [pkName]: {
          value: "~new~",
          type: "string",
        },
        [skName]: {
          value: "~new~",
          type: "string",
        },
        __id: v4() as any,
      },
    ]);
  };

  const removePartition = ({ cell }: { cell: Cell }) => {
    const partitionKey = cell.value;

    setData((oldData) => [
      ...oldData.filter(
        (row) =>
          !row[pkName] ||
          (row[pkName] && row[pkName].value !== partitionKey.value)
      ),
    ]);
  };

  const removeSk = ({ row }: { row: Row; computedSk?: string }) => {
    setData((oldData) => {
      return [
        ...oldData.filter(
          (r, id) => JSON.stringify(r) !== JSON.stringify(row.original)
        ),
      ];
    });
  };

  const addAttributeToAllInstancesOfModel = (
    attributeName: string,
    modelName: string
  ) => {
    setData((oldData) => {
      return oldData.map((row) => {
        if (
          row[entityTypeAttributeName] &&
          row[entityTypeAttributeName].value === modelName
        ) {
          return {
            ...row,
            [attributeName]: {
              type: "string",
              value: "~new~",
            },
          };
        }

        return row;
      });
    });
  };

  const promptChangingTemplateInAllModelInstances = (
    columnId: string,
    row: Row,
    newTemplate: string
  ) => {
    const shouldShowToast =
      state.data.filter(
        (r) =>
          r[entityTypeAttributeName] &&
          r[entityTypeAttributeName].value ===
            (row.original as any)[entityTypeAttributeName].value
      ).length > 1;

    if (!shouldShowToast) {
      return;
    }

    if (
      (row.original as any)[columnId] &&
      (row.original as any)[columnId].template === newTemplate
    ) {
      return;
    }

    toast((t) => (
      <span>
        <Text mb={1}>
          Do you want to change the template in all instances of this model?
        </Text>
        <Button
          onClick={() => {
            toast.dismiss();
            setData((oldData) => {
              return oldData.map((r) => {
                if (
                  r[entityTypeAttributeName] &&
                  r[entityTypeAttributeName].value ===
                    (row.original as any)[entityTypeAttributeName].value
                ) {
                  return {
                    ...r,
                    [columnId]: {
                      ...r[columnId],
                      template: newTemplate,
                    },
                  };
                }

                return r;
              });
            });
          }}
        >
          Yes, Update
        </Button>
      </span>
    ));
  };

  const checkIfEnteringExistingModelInstance = (
    modelName: string,
    columnId: string,
    row: Row
  ) => {
    if (
      columnId === entityTypeAttributeName &&
      ((row as any).original[entityTypeAttributeName] &&
        (row as any).original[entityTypeAttributeName].value) !== modelName
    ) {
      const models = computeModelsFromState(state, entityTypeAttributeName);
      const model = models.find((m) => m.type === modelName);

      if (modelName === "~new~") {
        return;
      }

      if (model) {
        toast(
          (t) => (
            <span>
              <Text mb={1}>
                Looks like you're trying to add an instance of "{modelName}"
                model. Would you like to prepopulate relevant attributes with
                sample data?
              </Text>
              <button
                onClick={() => {
                  const modelInstance: Record<string, Attribute> = {
                    [entityTypeAttributeName]: {
                      value: model.type,
                      type: "string",
                    },
                    [pkName]: ((row as any).original as any)[pkName],
                  };
                  Object.entries(model.attributes).forEach(([key, value]) => {
                    if (key === pkName || !value) {
                      return;
                    }

                    const previousValue = (row as any).original[key]?.value;

                    modelInstance[key] = {
                      type: value.type,
                      value: previousValue || value.default || "~new~",
                      template: value.template,
                    };

                    if (key === "__id") {
                      modelInstance[key] = v4() as any;
                    }
                  });

                  modelInstance[entityTypeAttributeName] = {
                    value: model.type,
                    type: "string",
                  };

                  setData((oldData) => {
                    return [
                      ...oldData.filter((r, id) => {
                        return r.__id !== (row.original as any).__id;
                      }),
                      {
                        ...modelInstance,
                      },
                    ].sort(sortByPartitions(pkName));
                  });

                  toast.dismiss(t.id);
                }}
              >
                Yes, populate
              </button>
            </span>
          ),
          {
            duration: 10000,
          }
        );
      }
    }
  };

  const updateMyData = (
    rowIndex: number,
    columnId: string,
    value: Attribute
  ) => {
    setData((oldData) => {
      return oldData
        .map((row) => {
          const renderableData = oldData.filter((row) => !!row[pkName]);
          const updatedRowInRenderableData = renderableData.find(
            (r, i) => i === rowIndex
          );
          let rowIndexInRenderableData = -1;
          renderableData.forEach((renderableRow, i) => {
            if (JSON.stringify(renderableRow) === JSON.stringify(row)) {
              rowIndexInRenderableData = i;
            }
          });
          const rowInRenderableData = renderableData[rowIndexInRenderableData];

          // If changing PK, affect all rows in that partition
          if (
            columnId === pkName && // if it's the PK
            rowInRenderableData && // if the row has been found in the current index view
            updatedRowInRenderableData &&
            updatedRowInRenderableData[columnId].value ===
              rowInRenderableData[columnId].value
          ) {
            console.log("Updating", { rowIndex, columnId, value });

            return {
              ...rowInRenderableData,
              [columnId]: value,
            };
          } else if (rowIndexInRenderableData === rowIndex) {
            console.log("Updating", { rowIndex, columnId, value });

            return {
              ...rowInRenderableData,
              [columnId]: value,
            };
          }

          return row;
        })
        .sort(sortByPartitions(pkName));
    });
  };

  const columns = React.useMemo(
    () =>
      [
        {
          Header: currentIndex.name,
          columns: [
            partitionKeyColumn({
              readOnly,
              pkName,
              addPartitionRow,
              removePartition,
              addPartition:
                currentIndex.name === state.indexes[0].name
                  ? addPartition
                  : undefined,
            }),
            sortKeyColumn({
              readOnly,
              skName,
              removeSk,
              shouldShowRemoveOption:
                currentIndex.name === state.indexes[0].name,
              promptChangingTemplateInAllModelInstances,
            }),
          ],
        },
        entityTypeAttributePosition === "After Primary Index"
          ? {
              Header: "Entity Type",
              columns: [
                attributesColumn({
                  readOnly,
                  att: entityTypeAttributeName,
                  isLast:
                    currentView === "table" &&
                    nonAnyKeyAttributesList.length < 2,
                  removeAttributeFromAllItems,
                  setIsAddingNewColumn,
                  areAttributesGroupped: currentView === "json",
                  entityTypeAttributeName,
                  checkIfEnteringExistingModelInstance,
                  notRemovable: true,
                }),
              ],
            }
          : undefined,
        ...state.indexes
          .filter((i) => i.name !== currentIndex.name && areOtherKeysVisible)
          .map((index) =>
            otherIndexColumn({
              readOnly,
              index,
              removeAttributeFromAllItems,
              excludedColumns: state.excludedColumns,
              onClick: () => changeActiveIndex(index),
            })
          ),
        {
          id: "attributes",
          Header: () => (
            <div>
              {" "}
              <TableIcon
                width={12}
                height={12}
                color="gray"
                style={{ marginBottom: "-2px", marginRight: "2px" }}
              />
              Attributes
            </div>
          ),
          columns: [
            ...nonAnyKeyAttributesList
              .filter((att) => att !== "__id" && !!att)
              .filter(
                (att) =>
                  !state.excludedColumns ||
                  state.excludedColumns.length === 0 ||
                  !state.excludedColumns.includes(att)
              )
              .filter(
                (att) =>
                  !(
                    att === entityTypeAttributeName &&
                    entityTypeAttributePosition === "After Primary Index"
                  )
              )
              .map((att, index) => {
                return attributesColumn({
                  readOnly,
                  att,
                  isLast: nonAnyKeyAttributesList.length - 2 === index,
                  removeAttributeFromAllItems,
                  setIsAddingNewColumn,
                  areAttributesGroupped: currentView === "json",
                  entityTypeAttributeName,
                  setMapAttributesModalState,
                  checkIfEnteringExistingModelInstance,
                });
              })
              .filter((c) => c.shouldBeRendered() && currentView === "table"),
            isAddingNewColumn
              ? NewColumn({ addAttributeToAllItems, setIsAddingNewColumn })
              : undefined,
            currentView === "attributes"
              ? OtherAttributesListColumn({
                  readOnly,
                  setMapAttributesModalState,
                  entityTypeAttributeName,
                  nonAnyKeyAttributesList,
                  updateMyData,
                  addAttributeToAllInstancesOfModel,
                })
              : undefined,
            currentView === "json"
              ? otherAttributesColumn({
                  readOnly,
                  entityTypeAttributeName,
                  nonAnyKeyAttributesList,
                  setAttributesModalState,
                  blobTruncateLimit: attributesTruncateLength,
                })
              : undefined,
          ].filter(Boolean),
        },
      ].filter(Boolean),
    [
      pkName,
      skName,
      (state.excludedColumns || []).length,
      nonPrimaryKeyAttributesList.length,
      currentIndex,
      isAddingNewColumn,
      currentView,
      areOtherKeysVisible,
      entityTypeAttributePosition,
      state.indexes.length,
    ]
  );

  const updateRow = (rowIndex: number, attributesBlob: any) => {
    setData((oldData) => {
      return oldData.map((row) => {
        const renderableData = oldData.filter((row) => !!row[pkName]);
        let rowIndexInRenderableData = -1;
        renderableData.forEach((renderableRow, i) => {
          if (JSON.stringify(renderableRow) === JSON.stringify(row)) {
            rowIndexInRenderableData = i;
          }
        });

        if (rowIndexInRenderableData === rowIndex) {
          console.log("Updating row", { rowIndex });

          return {
            ...renderableData[rowIndexInRenderableData],
            ...attributesBlob,
          };
        }

        return row;
      });
    });
  };

  const models = computeModelsFromState(state, entityTypeAttributeName);

  return (
    <div id="single-table-designer">
      <Sidebar
        readOnly={readOnly}
        modelId={state.modelName}
        setHighlightedAccessPattern={setHighlightedAccessPattern}
        setQueryState={setQueryState}
        setIsQueryBuilderVisible={setIsQueryBuilderVisible}
        isQueryBuilderVisible={readOnly || isQueryBuilderVisible}
        changeActiveIndexByName={changeActiveIndexByName}
      />
      <div
        style={{
          width:
            readOnly || isQueryBuilderVisible ? "calc(100% - 240px)" : "100%",
        }}
      >
        <Toolbar
          isReadOnly={readOnly}
          onExport={() => onExport(exportModel(state, entityTypeAttributeName))}
          onOnetableExport={() =>
            onOnetableExport(
              exportModel(state, entityTypeAttributeName).ModelSchema
            )
          }
          onDynamoDBToolboxModelExport={() =>
            onDynamoDBToolboxModelExport(
              generateDynamoDBToolboxCode(state, entityTypeAttributeName)
            )
          }
          setCurrentView={setCurrentView}
          currentView={currentView}
          modelId={state.modelName}
          setState={setState}
          indexes={state.indexes}
          undoState={undoState}
          isUndoActive={historyEntries.length > 0}
          removeGSI={removeGSI}
          onActiveIndexChange={changeActiveIndex}
          setIsGSIModalVisible={setIsAddGSIModalVisible}
          setIsQueryBuilderVisible={setIsQueryBuilderVisible}
          setQueryState={setQueryState}
          setAreOtherKeysVisible={setAreOtherKeysVisible}
          areOtherKeysVisible={areOtherKeysVisible}
          isQueryBuilderVisible={isQueryBuilderVisible}
          isExcludedColumnsMenuVisible={isExcludedColumnsMenuVisible}
          setExcludedColumnsMenuVisible={setExcludedColumnsMenuVisible}
          setIsSettingsModalVisible={setIsSettingsModalVisible}
          excludedColumnsCount={
            state.excludedColumns ? state.excludedColumns.length : 0
          }
          setEmptyState={setEmptyState}
          deployTable={deployTable}
          pkName={pkName}
          skName={skName}
          entityTypeAttributeName={entityTypeAttributeName}
        />
        {isExcludedColumnsMenuVisible && (
          <div style={{ position: "relative" }} ref={ref}>
            <ExcludedAttributesMenu
              excludedAttributes={state.excludedColumns}
              attributesList={nonPrimaryKeyAttributesList}
              setExcluded={(excludedColumns: string[]) => {
                setState((s) => ({
                  ...s,
                  excludedColumns,
                }));
              }}
            />
          </div>
        )}
        {isEmptyState && (
          <EmptyStateScreen
            onImport={() => {}}
            setState={setState}
            disableEmptyState={() => setEmptyState(false)}
            setEntityTypeAttributeName={setEntityTypeAttributeName}
          />
        )}
        <div
          style={{
            // height: "calc(100vh - 94px)",
            overflowY: "scroll",
            display: isEmptyState ? "none" : "block",
          }}
        >
          <QueryBuilder
            modelId={state.modelName}
            queryState={queryState}
            setQueryState={setQueryState}
            isVisible={readOnly || isQueryBuilderVisible}
            indexes={state.indexes}
            data={state.data}
            readOnly={readOnly}
            nonKeyAttributes={nonPrimaryKeyAttributesList}
            useForceUpdate={forceUpdate}
            changeActiveIndex={changeActiveIndex}
          />
          {currentView !== "models" && (
            <Table
              readOnly={readOnly}
              tableStyle={tableStyle}
              columns={columns}
              data={renderableData}
              isEmptyState={isEmptyState}
              isSearchActive={isQueryBuilderVisible}
              updateMyData={updateMyData}
              updateRow={updateRow}
              pkName={pkName}
              skName={skName}
              currentView={currentView}
            />
          )}
          {currentView === "models" && (
            <ModelsTable
              tableStyle={tableStyle}
              data={renderableData}
              columns={modelColumns(state, entityTypeAttributeName)}
              entityTypeAttributeName={entityTypeAttributeName}
            />
          )}
        </div>
        {isSettingsModalVisible && (
          <SettingsModal
            onClose={() => {
              setIsSettingsModalVisible(false);
              forceUpdate();
            }}
            setTableStyle={setTableStyle}
            isVisible={isSettingsModalVisible}
          />
        )}
        <EditAttributesModal
          saveAttributes={(blob) => {
            updateRow(attributesModalState.rowIndex, convertFromDDBJSON(blob));
          }}
          initialValue={attributesModalState.blob}
          isVisible={attributesModalState.isOpen}
          onClose={() => {
            setAttributesModalState((s) => ({ ...s, isOpen: false }));
          }}
          readOnly={readOnly}
          ddbJson={true}
        />
        <EditAttributesModal
          saveAttributes={(value) => {
            updateMyData(
              mapAttributeModalState.rowIndex,
              mapAttributeModalState.columnId,
              {
                type: "map",
                value,
              }
            );
          }}
          initialValue={mapAttributeModalState.blob}
          isVisible={mapAttributeModalState.isOpen}
          onClose={() => {
            setMapAttributesModalState((s) => ({ ...s, isOpen: false }));
          }}
          ddbJson={false}
        />
        {!readOnly && (
          <PartitionKeyContextMenu
            updateMyData={updateMyData}
            addPartitionRow={addPartitionRow}
            addPartition={addPartition}
            removePartition={removePartition}
          />
        )}
        {!readOnly && (
          <SortKeyContextMenu
            updateMyData={updateMyData}
            removeSk={removeSk}
            duplicateRow={duplicateRow}
          />
        )}
        {!readOnly && <GSIContextMenu deleteGSI={removeGSI} />}
        {!readOnly && (
          <AttributeContextMenu
            pkName={pkName}
            updateMyData={updateMyData}
            duplicateRow={duplicateRow}
            excludeColumn={excludeColumn}
            entityTypeAttributeName={entityTypeAttributeName}
            removeAttributeFromAllItems={removeAttributeFromAllItems}
            models={models}
            addPartitionRow={addPartitionRow}
          />
        )}
        <NewGSIModal
          addGSI={addGSI}
          isVisible={isAddGSIModalVisible}
          indexes={state.indexes}
          onClose={() => setIsAddGSIModalVisible(false)}
        />
        {false && showModelOutput && (
          <Box mt={4}>
            <ReactJson
              src={exportModel(state, entityTypeAttributeName)}
              collapsed={true}
            />
          </Box>
        )}
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: AppProps) => (
  <ErrorBoundary
    FallbackComponent={({ error, resetErrorBoundary }) => {
      return (
        <div role="alert">
          <p>
            Whoops! Looks like single-table designer crashed! Error details
            below:{" "}
          </p>
          <pre>{error.message}</pre>
          <button onClick={resetErrorBoundary}>Reload designer</button>
        </div>
      );
    }}
    onReset={() => {}}
  >
    <App {...props} />
  </ErrorBoundary>
);

function sortByPartitions(pkName: string) {
  return (a: any, b: any) => {
    if (a[pkName] && b[pkName] && a[pkName].value > b[pkName].value) {
      return 1;
    } else if (a[pkName] && b[pkName] && a[pkName].value < b[pkName].value) {
      return -1;
    }
    return 0;
  };
}
