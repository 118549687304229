import {
  PlusCircleIcon,
  TemplateIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { useMemo } from "react";
import { TableInstance, useExpanded, useGroupBy, useTable } from "react-table";
import { getSetting } from "./helpers/settings";

function useControlledState(state: any) {
  return useMemo(() => {
    if (state.groupBy.length) {
      return {
        ...state,
        hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter(
          (d, i, all) => all.indexOf(d) === i
        ),
      };
    }
    return state;
  }, [state]);
}

function defaultGroupByFn(rows: any[], columnId: string) {
  return rows.reduce((prev, row, i) => {
    const val = row.values[columnId];
    if (val) {
      const obj = val.value;
      const resKey = `${obj}`;
      prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : [];
      prev[resKey].push(row);
    }
    return prev;
  }, {});
}

export function ModelsTable({
  columns,
  data,
  entityTypeAttributeName,
  tableStyle,
}: any) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        groupByFn: defaultGroupByFn,
        initialState: {
          groupBy: [entityTypeAttributeName],
        },
      } as any,
      useGroupBy,
      useExpanded,
      (hooks) => {
        hooks.useControlledState.push(useControlledState);
        hooks.visibleColumns.push((columns, { instance }) => {
          if (!(instance.state as any).groupBy.length) {
            return columns;
          }

          return [
            {
              id: "expander",
              Header: ({
                allColumns,
                state: { groupBy },
              }: {
                state: any;
                allColumns: any[];
              }) => {
                return groupBy.map((columnId: string) => {
                  const column = allColumns.find((d) => d.id === columnId)!;

                  return (
                    <span {...column.getHeaderProps()}>
                      {column.canGroupBy ? (
                        <span {...column.getGroupByToggleProps()}>
                          <TemplateIcon
                            width={16}
                            height={16}
                            color="gray"
                            style={{ marginBottom: "-3px", marginRight: "4px" }}
                          />
                        </span>
                      ) : null}
                      {column.render("Header")}{" "}
                    </span>
                  );
                });
              },
              Cell: ({ row }: { row: any }) => {
                if (row.canExpand) {
                  const groupedCell = row.allCells.find(
                    (d: any) => d.isGrouped
                  );

                  return (
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          // We can even use the row.depth property
                          // and paddingLeft to indicate the depth
                          // of the row
                          paddingLeft: `${row.depth * 2}rem`,
                          display: "flex",
                        },
                      })}
                    >
                      {row.isExpanded ? (
                        <XCircleIcon
                          color="gray"
                          width={16}
                          height={16}
                          style={{ marginBottom: "-3px", marginRight: "4px" }}
                        />
                      ) : (
                        <PlusCircleIcon
                          color="gray"
                          width={16}
                          height={16}
                          style={{ marginBottom: "-3px", marginRight: "4px" }}
                        />
                      )}{" "}
                      <div>
                        {groupedCell.render("Cell")} ({row.subRows.length})
                      </div>
                    </span>
                  );
                }

                return null;
              },
            },
            ...columns,
          ];
        });
      }
    ) as TableInstance<{}> & { rowSpanHeaders: any[]; updateMyData: any };

  // Render the UI for your table
  return (
    <div id="models-table" className={getSetting("singleTableDesignerStyle")}>
      <table {...(getTableProps() as any)}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...(headerGroup.getHeaderGroupProps() as any)}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>
                  {column.canGroupBy ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? (
                        "🛑 "
                      ) : (
                        <TemplateIcon
                          width={16}
                          height={16}
                          color="gray"
                          style={{ marginBottom: "-3px", marginRight: "4px" }}
                        />
                      )}
                    </span>
                  ) : null}
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...(getTableBodyProps() as any)}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...(row.getRowProps() as any)}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        background: cell.isGrouped
                          ? "#e0eaff"
                          : cell.isAggregated
                          ? "#e0eaff"
                          : cell.isPlaceholder
                          ? "#ff000042"
                          : "white",
                      }}
                    >
                      {cell.isAggregated ? (
                        cell.render("Aggregated")
                      ) : cell.isPlaceholder ? (
                        <div></div>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
