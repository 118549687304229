/* eslint-disable jsx-a11y/anchor-is-valid */
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "rc-tooltip";
import { Flex } from "rebass";
import { Cell } from "react-table";
import EditableCell from "../EditableCell";

type PartitionKeyColumnProps = {
  pkName: string;
  addPartitionRow: ({ cell }: { cell: Cell }) => void;
  addPartition?: () => void;
  removePartition: ({ cell }: { cell: Cell }) => void;
  readOnly?: boolean;
};

export const partitionKeyColumn = ({
  pkName,
  addPartitionRow,
  removePartition,
  addPartition,
  readOnly,
}: PartitionKeyColumnProps) => {
  return {
    Header: () => (
      <div onClick={addPartition ? addPartition : () => {}} style={{ cursor: "pointer" }} className="otherColumnHeader">
        {pkName}

        {addPartition && !readOnly && (
          <PlusCircleIcon width={16} height={16} color="gray" style={{ marginBottom: "-3px", marginRight: "2px" }} />
        )}
      </div>
    ),
    accessor: pkName,
    enableRowSpan: true,
    Cell: ({ ...restOfProps }: any) => (
      <>
        <EditableCell
          value={restOfProps.cell.value}
          row={restOfProps.row}
          column={restOfProps.column}
          updateMyData={restOfProps.updateMyData}
          disableTypeSelector
          readOnly={readOnly}
        />
        {!readOnly && (
          <Flex>
            <ReactTooltip
              id={`addRowInPartition-${restOfProps.cell.value.value}`}
              mouseLeaveDelay={0}
              mouseEnterDelay={0.5}
              placement="right"
              overlay={<span>Add row in partition {restOfProps.cell.value.value}</span>}
            >
              <PlusCircleIcon
                id="add-partition-row"
                width={16}
                height={16}
                style={{ cursor: "pointer" }}
                onClick={() => addPartitionRow(restOfProps)}
              />
            </ReactTooltip>

            <ReactTooltip
              id={`removePartition-${restOfProps.cell.value.value}`}
              mouseLeaveDelay={0}
              mouseEnterDelay={0.5}
              placement="right"
              overlay={<span>Remove partition {restOfProps.cell.value.value}</span>}
            >
              <MinusCircleIcon
                width={16}
                height={16}
                style={{ cursor: "pointer" }}
                onClick={() => removePartition(restOfProps)}
              />
            </ReactTooltip>
          </Flex>
        )}
      </>
    ),
  };
};
