const GSISchema = {
  type: "object",
  required: ["indexName"],
  properties: {
    indexName: {
      type: "string",
      title: "Index Name",
      description: "The name of the secondary index.",
    },
    partitionKey: {
      type: "string",
      title: "Partition Key",
      description: "Partition key attribute name",
    },
    sortKey: {
      type: "string",
      title: "Sort Key",
      description: "Sort key attribute name",
    },
    projection: {
      type: "string",
      enum: ["KEYS_ONLY", "INCLUDE", "ALL"],
      default: "ALL",
      title: "Projection",
      description:
        "Represents attributes that are copied (projected) from the table into an index. These are in addition to the primary key attributes and index key attributes, which are automatically projected.",
    },
  },
  dependencies: {
    projection: {
      oneOf: [
        {
          properties: {
            projection: {
              enum: ["None"],
            },
          },
        },
        {
          properties: {
            projection: {
              enum: ["All"],
            },
          },
        },
        {
          properties: {
            projection: {
              enum: ["Selected"],
            },
            attributes: {
              type: "string",
              title: "Projected Attributes",
              description: "Comma separated",
            },
          },
        },
      ],
    },
  },
};

export default GSISchema;
