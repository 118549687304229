import { Attribute } from "../App";

type NewColumnProps = {
  setIsAddingNewColumn: (isAddingNewColumn: boolean) => void;
  addAttributeToAllItems: (attribute: Attribute) => void;
};

export const NewColumn = ({
  addAttributeToAllItems,
  setIsAddingNewColumn,
}: NewColumnProps) => {
  return {
    Header: (
      <input
        autoFocus
        placeholder="Attribute Name"
        style={{ backgroundColor: "white" }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            addAttributeToAllItems({
              type: "string",
              value: (e.target as any).value,
            });
            setIsAddingNewColumn(false);
          }
        }}
        onBlur={(e) => {
          addAttributeToAllItems({
            type: "string",
            value: (e.target as any).value,
          });
          setIsAddingNewColumn(false);
        }}
      />
    ),
    accessor: "_____",
  };
};
