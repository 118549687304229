import { SyntheticEvent } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { Index } from "./App";

type GSIContextMenuProps = {
  deleteGSI: (index: Index) => void;
};

const GSIContextMenu = ({ deleteGSI }: GSIContextMenuProps) => (
  <ContextMenu id="gsi-menu">
    <MenuItem
      onClick={(_: SyntheticEvent, index: Index) => {
        deleteGSI(index);
      }}
    >
      Delete GSI
    </MenuItem>
  </ContextMenu>
);

export default GSIContextMenu;
