import axios from "axios";
import { useEffect, useState } from "react";
import { Flex } from "rebass";
import App, { AppProps } from "./App";
import { importModel } from "./Importer";
import { useFilePicker } from "use-file-picker";

export const ReadOnly = (props: AppProps) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const modelUrl = params["modelUrl"];
  const [initialState, setInitialState] = useState<any>(undefined);
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    multiple: false,
    accept: [".json"],
  });

  if (filesContent && filesContent.length > 0) {
    try {
      const ddbModel = JSON.parse(filesContent[0].content);

      setInitialState(importModel(ddbModel));
    } catch (error) {
      console.error(error);
    } finally {
      clear();
    }
  }

  useEffect(() => {
    const fetchModel = async () => {
      if (!modelUrl) {
        return;
      }

      const model = await axios.get(modelUrl);
      setInitialState(importModel(model.data));
    };
    fetchModel();
  }, [modelUrl]);

  if (!modelUrl && !initialState) {
    return <ModelEmpty openFileSelector={openFileSelector} />;
  }

  if (!initialState) {
    return <Loading setInitialState={setInitialState} url={modelUrl} />;
  }

  return (
    <>
      <App {...props} readOnly={true} initialState={initialState} />
    </>
  );
};

const ModelEmpty = ({ openFileSelector }: any) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100vh"
      flexDirection="column"
    >
      <h3 style={{ maxWidth: "490px" }}>Single-table models viewer</h3>

      <div
        style={{ fontSize: "14px", maxWidth: "490px", paddingBottom: "80px" }}
      >
        <p style={{ color: "#555" }}>
          Loading a model can be done in two ways:
        </p>
        <p style={{ color: "#555" }}>
          1.{" "}
          <a href="/#" onClick={() => openFileSelector()}>
            Click here
          </a>{" "}
          to load a single-table model exported from Dynobase or NoSQL Workbench
          from your computer.
        </p>
        <p style={{ color: "#555" }}>
          2. Supply the URL to the NoSQL Workbench /{" "}
          <a
            href="https://dynobase.dev/?ref=viewer"
            rel="noreferrer"
            target="_blank"
          >
            Dynobase
          </a>
          -compatible model in the JSON format inside the URL using{" "}
          <code>?modelUrl</code> query parameter.
        </p>
        <a href="/?modelUrl=https://raw.githubusercontent.com/Dynobase/single-table-models/main/Movies/movies.json">
          See Example →
        </a>
      </div>
    </Flex>
  );
};

const Loading = ({ setInitialState, url }: any) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100vh"
      flexDirection="column"
    >
      <p>Loading...</p>

      <p
        style={{
          color: "#555",
          fontSize: "12px",
          marginTop: 0,
        }}
      >
        Fetching model from {url}
      </p>
      {/* <div onClick={() => setInitialState(model)}>test</div> */}
    </Flex>
  );
};
