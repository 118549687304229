import React, { useState } from "react";
import { Flex } from "rebass";
import { Select, Input } from "@rebass/forms";
import { Attribute, Index } from "./App";
import AutocompleteInput from "./AutocompleteInput";
import {
  AttributeType,
  BinaryOperator,
  BoolOperator,
  FilterExpression,
  NullOperator,
  NumberOperator,
} from "./FilterExpression";
import Selector from "./Selector";
import Button from "./Button";
import { Popover } from "@headlessui/react";
import { saveQuery } from "./Query";
import { MinusCircleIcon } from "@heroicons/react/outline";

type QueryBuilderProps = {
  modelId: string;
  indexes: Index[];
  isVisible: boolean;
  data: Record<string, Attribute>[];
  nonKeyAttributes: string[];
  setQueryState: (state: QueryBuilderState) => void;
  queryState: QueryBuilderState;
  useForceUpdate: () => void;
  changeActiveIndex: (index: Index) => void;
  readOnly?: boolean;
};

enum StringOperator {
  Equal = "=",
  NotEqual = "!=",
  GreaterThan = ">",
  LessThan = "<",
  GreaterEqualThan = ">=",
  LessEqualThan = "<=",
  // Between = "Between",
  Contains = "Contains",
  NotContains = "Not Contains",
  BeginsWith = "Begins With",
  Exists = "Exists",
  NotExists = "Not Exists",
}

enum SKOperator {
  Equal = "=",
  NotEqual = "!=",
  GreaterThan = ">",
  LessThan = "<",
  GreaterEqualThan = ">=",
  LessEqualThan = "<=",
  BeginsWith = "Begins With",
  // Between = "Between",
}

export type QueryBuilderState = {
  currentIndex: string;
  pkValue: string;
  skOperator: StringOperator;
  skValue: string;
  filterExpressions: FilterExpression[];
};

const QueryBuilder = (props: QueryBuilderProps) => {
  const { queryState, setQueryState } = props;
  const [queryName, setQueryName] = useState("");

  const currentIndex = props.indexes.find((index) => index.name === queryState.currentIndex)!;

  const partitionKeys = props.data.map((r) => r[currentIndex.hash]).filter(Boolean);

  const sortKeys = props.data.map((r) => r[currentIndex.sort]).filter(Boolean);

  if (!props.isVisible) {
    return <div></div>;
  }

  return (
    <div
      style={{ padding: "10px", borderLeft: "1px solid #ccc", borderBottom: "1px solid #ccc" }}
      className="table-designer-query-builder"
    >
      <Selector
        defaultValue={queryState.currentIndex}
        options={props.indexes.map((i) => i.name)}
        setSelectedValue={(value) => {
          setQueryState({ ...queryState, currentIndex: value });
          props.changeActiveIndex(props.indexes.find((i) => i.name === value)!);
        }}
      />
      <Flex alignItems="center" m={2}>
        <span style={{ marginRight: "10px" }}>{currentIndex!.hash}</span>
        <span
          style={{
            marginLeft: "71px",
            marginRight: "72px",
          }}
        >
          =
        </span>
        <AutocompleteInput
          value={queryState.pkValue}
          placeholder="PK Value"
          style={{
            height: "36px",
            minWidth: "100px",
            backgroundColor: "#fff",
            border: "solid 1px #d5d5d5",
            outline: 0,
          }}
          autocompleteValues={Array.from(new Set(partitionKeys.map((p) => p.value as string)))}
          onChange={(value) => {
            setQueryState({ ...queryState, pkValue: value });
          }}
        />
      </Flex>
      <Flex alignItems="center" m={2}>
        <span style={{ marginRight: "10px" }}>{currentIndex!.sort}</span>

        <Select
          mx={2}
          style={{
            outline: "none",
            borderRadius: 0,
            height: "36px",
            backgroundColor: "#fff",
            border: "solid 1px #d5d5d5",
            minWidth: "140px",
          }}
          value={queryState.skOperator}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setQueryState({
              ...queryState,
              skOperator: e.target.value as StringOperator,
            });
          }}
        >
          {Object.keys(SKOperator).map((operator: string) => (
            <option value={SKOperator[operator as keyof typeof SKOperator]} key={operator}>
              {SKOperator[operator as keyof typeof SKOperator]}
            </option>
          ))}
        </Select>
        <AutocompleteInput
          value={queryState.skValue}
          placeholder="SK Value"
          style={{
            height: "36px",
            minWidth: "100px",
            backgroundColor: "#fff",
            border: "solid 1px #d5d5d5",
            outline: 0,
          }}
          autocompleteValues={Array.from(new Set(...sortKeys.map((p) => p.value as string)))}
          onChange={(value) => {
            setQueryState({ ...queryState, skValue: value });
          }}
        />
      </Flex>

      {queryState.filterExpressions.length > 0 && <h3>Filter Expressions</h3>}

      {queryState.filterExpressions.map((f) =>
        renderFilterExpression(
          f,
          (id: string, newFilterExpression: FilterExpression) => {
            const pristineFilterExpressions = queryState.filterExpressions.filter((f) => f.id !== id);

            const newFilterExpressions = [...pristineFilterExpressions, newFilterExpression].sort(
              (a, b) => a.createdAt - b.createdAt,
            );

            setQueryState({
              ...queryState,
              filterExpressions: newFilterExpressions,
            });
          },
          (id: string) =>
            setQueryState({
              ...queryState,
              filterExpressions: queryState.filterExpressions.filter((f) => f.id !== id),
            }),
          props.nonKeyAttributes,
        ),
      )}

      <Flex mt={2}>
        {!props.readOnly && (
          <Button
            className="add-filter-expression-button"
            style={{
              fontSize: 16,
              marginRight: "5px",
              padding: "9px 7px",
              backgroundColor: "#ffffff",
              color: "#464646",
            }}
            onClick={() =>
              setQueryState({
                ...queryState,
                filterExpressions: [
                  ...queryState.filterExpressions,
                  {
                    id: generateRandomId(4),
                    attributeName: "",
                    attributeValue: "",
                    attributeType: AttributeType.String,
                    operator: StringOperator.Equal,
                    logicalEvaluation: "AND",
                    createdAt: +new Date(),
                  },
                ],
              })
            }
          >
            + Add Filter Expression
          </Button>
        )}

        {!props.readOnly && (
          <Popover
            style={{
              position: "relative",
            }}
          >
            <Popover.Button as="div" style={{ background: "transparent", border: 0, padding: 0 }}>
              <Button
                style={{
                  fontSize: 16,
                  marginRight: "8px",
                  backgroundColor: "#ffffff",
                  color: "#464646",
                  padding: "9px",
                }}
              >
                Save Query As...
              </Button>
            </Popover.Button>
            <Popover.Panel
              style={{
                background: "white",
                boxShadow: "5px 5px 10px #ddd",
                position: "absolute",
                padding: "8px",
                border: "1px solid #d5d5d5",
              }}
            >
              {({ close }) => (
                <Flex>
                  <input
                    autoFocus
                    value={queryName}
                    onChange={(e) => setQueryName(e.target.value)}
                    placeholder="Query Name"
                    style={{
                      minWidth: "100px",
                      backgroundColor: "#fff",
                      border: "solid 1px #d5d5d5",
                      outline: 0,
                      padding: "4px",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        saveQuery(props.modelId, {
                          ...queryState,
                          name: queryName,
                        });
                        close();
                        setQueryName("");
                      }
                    }}
                  ></input>
                  <Button
                    style={{
                      border: "solid 1px #d5d5d5",
                      borderRadius: 0,
                      fontSize: 16,
                      backgroundColor: "#ffffff",
                      color: "#464646",
                    }}
                    onClick={() => {
                      saveQuery(props.modelId, {
                        ...queryState,
                        name: queryName,
                      });
                      close();
                      props.useForceUpdate();
                      setQueryName("");
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              )}
            </Popover.Panel>
          </Popover>
        )}
        <Button
          style={{
            fontSize: 16,
            marginRight: "8px",
            backgroundColor: "#ffffff",
            color: "#464646",
            padding: "9px",
          }}
          onClick={() =>
            setQueryState({
              currentIndex: "primary",
              pkValue: "",
              skOperator: StringOperator.Equal,
              skValue: "",
              filterExpressions: [],
            })
          }
        >
          Reset Query
        </Button>
      </Flex>
    </div>
  );
};

const renderFilterExpression = (
  filterExpression: FilterExpression,
  changeFilterExpression: (id: string, arg2: any) => void,
  removeFilterExpression: (id: string) => void,
  nonKeyAttributes: string[],
) => {
  return (
    <Flex my={1} key={filterExpression.id} alignItems="center">
      <AutocompleteInput
        style={{
          width: "185px",
          border: "solid 1px #d5d5d5",
          backgroundColor: "white",
          height: "36px",
        }}
        value={filterExpression.attributeName}
        autocompleteValues={nonKeyAttributes}
        placeholder="Attribute name"
        onChange={(value: string) => {
          changeFilterExpression(filterExpression.id, {
            ...filterExpression,
            attributeName: value,
          });
        }}
      />

      <Select
        mx={2}
        style={{
          outline: "none",
          borderRadius: 0,
          height: "36px",
          backgroundColor: "#fff",
          border: "solid 1px #d5d5d5",
          minWidth: "140px",
        }}
        value={filterExpression.operator}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          changeFilterExpression(filterExpression.id, {
            ...filterExpression,
            operator: e.target.value,
          });
        }}
      >
        {getOperators(filterExpression.attributeType).map((operator: string) => (
          <option value={StringOperator[operator as keyof typeof StringOperator]} key={operator}>
            {StringOperator[operator as keyof typeof StringOperator]}
          </option>
        ))}
      </Select>

      {filterExpression.operator !== "Not Exists" && filterExpression.operator !== "Exists" && (
        <Input
          id="value"
          name="value"
          value={filterExpression.attributeValue}
          placeholder="Attribute value"
          style={{
            width: "200px",
            marginLeft: "4px",
            border: "solid 1px #d5d5d5",
            height: "36px",
            backgroundColor: "white",
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            changeFilterExpression(filterExpression.id, {
              ...filterExpression,
              attributeValue: e.target.value,
            });
          }}
        />
      )}
      {/* {alternativeTypes(filterExpression.attributeValue).length > 1 && (
        <>
          <span style={{ margin: "7px 5px 0 10px" }}>as</span>
          <Select
            mx={2}
            style={{
              outline: "none",
              borderRadius: 0,
              height: "36px",
              backgroundColor: "#fff",
              border: "solid 1px #aaa",
              minWidth: "100px",
            }}
            value={filterExpression.attributeType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              changeFilterExpression(filterExpression.id, {
                ...filterExpression,
                attributeType: e.target.value,
              });
            }}
          >
            {alternativeTypes(filterExpression.attributeValue).map(
              (type: string) => (
                <option value={type} key={type}>
                  {type}
                </option>
              )
            )}
          </Select>
        </>
      )} */}
      <MinusCircleIcon
        onClick={() => removeFilterExpression(filterExpression.id)}
        width={16}
        height={16}
        style={{ cursor: "pointer", marginLeft: "8px" }}
        color="#bbb"
      />
    </Flex>
  );
};

const getOperators = (attributeType: AttributeType) => {
  switch (attributeType) {
    case AttributeType.String:
      return Object.keys(StringOperator);
    case AttributeType.Boolean:
      return Object.keys(BoolOperator);
    case AttributeType.Binary:
      return Object.keys(BinaryOperator);
    case AttributeType.Number:
      return Object.keys(NumberOperator);
    case AttributeType.Null:
      return Object.keys(NullOperator);
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const alternativeTypes = (value: string): AttributeType[] => {
  const types = [AttributeType.String];

  if (value && !isNaN(value as any)) {
    types.push(AttributeType.Number);
  }

  try {
    if (!!value && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
      types.push(AttributeType.Boolean);
    }
  } catch (error) {}

  if (!!value && value.toLowerCase() === "null") {
    types.push(AttributeType.Null);
  }

  return types;
};

const generateRandomId = (repetitions = 1) => {
  let randomId = "";
  [...Array(repetitions)].forEach((repetition) => {
    randomId += Math.random().toString(36).substring(2, 15);
  });
  return randomId;
};

export default QueryBuilder;
