const NewModel = {
  type: "object",
  required: ["partitionKey", "sortKey", "entityTypeAttributeName", "modelName"],
  properties: {
    partitionKey: {
      type: "string",
      title: "Partition Key",
      description:
        "DynamoDB uses the partition key’s value as an input to an internal hash function. The output from the hash function determines the partition in which the item is stored. ",
    },
    sortKey: {
      type: "string",
      title: "Sort Key",
      description: "The sort key is used to sort items with the same partition",
    },
    entityTypeAttributeName: {
      type: "string",
      title: "Entity Type Attribute Name",
      description: 'Attribute used to distinguish different entity types, something like "type" or "entityType"',
    },
    modelName: {
      type: "string",
      title: "Model Name",
      description: "Name of your model",
    },
  },
};

export default NewModel;
