const defaultSettings = {
  attributesTruncateLength: 100,
  entityTypeAttributePosition: "After Primary Index",
  showModel: false,
};

export const getSettings = () => {
  const settingsString =
    localStorage.getItem("DYNOBASE_SINGLE_TABLE_DESIGNER_SETTINGS") ||
    JSON.stringify(defaultSettings);

  try {
    return JSON.parse(settingsString);
  } catch (error) {
    console.warn(
      "Failed to parse DYNOBASE_SINGLE_TABLE_DESIGNER_SETTINGS",
      error
    );
    return defaultSettings;
  }
};

export const getSetting = (key: string) => {
  return (getSettings() as any)[key];
};

export const setSetting = (key: string, value: any) => {
  const currentSettings = getSettings();
  const newSettings = {
    ...currentSettings,
    [key]: value,
  };

  localStorage.setItem(
    "DYNOBASE_SINGLE_TABLE_DESIGNER_SETTINGS",
    JSON.stringify(newSettings)
  );
};
