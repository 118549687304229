/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Column, Row } from "react-table";
import ReactTooltip from "rc-tooltip";
import { Flex } from "rebass";
import { Attribute } from "./App";
import { convertFromDDBType } from "./helpers/convertFromDDBJSON";
import { convertToDDBType } from "./helpers/convertToDDBJSON";
import { useFocus } from "./hooks/useFocus";
import "rc-tooltip/assets/bootstrap.css";

type DDBType = {
  type: string;
  description: string;
  color: string;
  inputType?: string;
};
const stringDDBType: DDBType = {
  type: "S",
  description: "String",
  color: "#e0eaff",
  inputType: "text",
};
const numberDDBType: DDBType = {
  type: "N",
  description: "Number",
  color: "#fff0e0",
  inputType: "number",
};
const booleanDDBType: DDBType = {
  type: "BOOL",
  description: "Boolean",
  color: "#ffcfcf",
  inputType: "checkbox",
};
const mapDDBType: DDBType = {
  type: "M",
  description: "Map",
  color: "#e7bfff",
};
const compositeStringDDBType: DDBType = {
  type: "CS",
  description: "Composite String",
  color: "#e4ffe0",
};

type EditableCellProps = {
  value: Attribute;
  row: Row;
  column: Column;
  updateMyData: any;
  disableTypeSelector?: boolean;
  disableValueEditor?: boolean;
  setMapAttributesModalState?: (arg: any) => void;
  checkIfEnteringExistingModelInstance?: (potentialModelName: any, columnId: string, row: Row) => void;
  promptChangingTemplateInAllModelInstances?: (columnId: string, row: Row, newTemplate: string) => void;
  readOnly?: boolean;
};

const EditableCell = (props: EditableCellProps) => {
  const {
    value: initialValue,
    row,
    column: { id },
    updateMyData,
  } = props;

  const [value, setValue] = useState<Attribute>(initialValue);
  const [isEditFocused, setEditFocused] = useState<boolean>(false);
  const [editableValue, setEditableValue] = useState<string>(value ? (value.value as string) : "");
  const [inputRef] = useFocus();

  const onChange = (e: any) => {
    const templateMatcher = /(\$\{[a-z]+\})/;
    const editedValueIsCandidateForTemplate = templateMatcher.test(e.target.value);
    setEditableValue(e.target.value);

    // If follows ${}#${} format, update template instead of value
    if (editedValueIsCandidateForTemplate) {
      setValue((current) => ({ ...current, template: e.target.value }));
    } else {
      if (typeof e.target.value === "number") {
        setValue((current) => ({
          ...current,
          value: e.target.value.toString(),
        }));
      }
    }
  };

  const onChangeType = (e: any) => {
    const val = e.target.value;

    if (val === "BOOL") {
      setValue(() => ({
        template: undefined,
        type: convertFromDDBType(val),
        value: true,
      }));
      updateMyData(row.index, id, {
        type: convertFromDDBType(val),
        value: true,
        template: undefined,
      });
    } else if (val === "M") {
      setValue(() => ({
        template: undefined,
        type: convertFromDDBType(val),
        value: {},
      }));
      setEditableValue({} as unknown as string);
      updateMyData(row.index, id, {
        type: convertFromDDBType(val),
        value: {},
        template: undefined,
      });
    } else {
      setValue((current) => ({ ...current, type: convertFromDDBType(val) }));
      updateMyData(row.index, id, {
        ...value,
        type: convertFromDDBType(val),
      });
    }
  };

  const onBoolValueChange = (stringBoolean: string) => {
    const boolVal = stringBoolean === "TRUE";
    updateMyData(row.index, id, {
      template: undefined,
      value: boolVal,
      type: "boolean",
    });
    setValue((current) => ({ ...current, value: boolVal }));
  };

  const onBlur = () => {
    if (props.checkIfEnteringExistingModelInstance) {
      props.checkIfEnteringExistingModelInstance(editableValue, props.column.id!, row);
    }

    setEditFocused(false);
    setEditableValue("");

    const templateMatcher = /(\$\{[a-zA-Z0-9]+\})/;
    const editedValueIsCandidateForTemplate = templateMatcher.test(editableValue);

    // If follows ${}#${} format, update template instead of value
    if (editedValueIsCandidateForTemplate) {
      const { renderedValue } = getTemplateDetails(editableValue, row);

      if (props.promptChangingTemplateInAllModelInstances) {
        props.promptChangingTemplateInAllModelInstances(id!, row, editableValue);
      }

      updateMyData(row.index, id, {
        template: editableValue,
        value: renderedValue,
        type: "string",
      });
    } else {
      if (
        (value && editableValue !== value.value && editableValue !== value.template) || // if value has changed
        (!value && editableValue) || // if set to something from null
        editableValue === "" // if set to empty string explicitly
      ) {
        updateMyData(row.index, id, {
          template: undefined,
          value: editableValue === "" ? undefined : editableValue,
          type: value ? value.type : "string",
        });
      }
    }
  };

  useEffect(() => {
    if (initialValue && initialValue.template) {
      const { isCorrect, renderedValue } = getTemplateDetails(initialValue.template, row);

      if (!isCorrect) {
        setValue({ ...initialValue, value: "!ERR" });
        return;
      }

      setValue({
        ...initialValue,
        value: renderedValue,
      });
    } else {
      setValue(initialValue);
    }
  }, [initialValue, row.cells, row, editableValue]);

  const computeStyle = () => {
    let isRefed = false;

    row.cells.forEach((cell) => {
      if (cell && cell.value && cell.value.template) {
        const { formattedParts } = getTemplateDetails(cell.value.template, row);

        if (formattedParts?.includes(id!)) {
          isRefed = true;
        }
      }
    });

    return isRefed ? "referred" : "";
  };

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      (e.currentTarget as any).blur();
    } else if (e.key === "Tab") {
      e.preventDefault();
      e.stopPropagation();

      const currentInput = document.getElementById(`input-${id}-${row.index}`);
      const rowElement =
        currentInput?.parentNode?.parentNode?.parentNode?.nodeName === "TR"
          ? currentInput?.parentNode?.parentNode?.parentNode
          : currentInput?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;

      let currentChildIndex = 99999;
      let prevInput: any;

      Array.from((rowElement as any).getElementsByClassName("editable-cell-input") || []).forEach(
        (childInput, childIndex) => {
          if (childInput && (childInput as any).id === `input-${id}-${row.index}`) {
            currentChildIndex = childIndex;
          }

          if (e.shiftKey) {
            if (childIndex === currentChildIndex && prevInput) {
              prevInput.focus();
              return;
            }
          } else {
            if (childIndex === currentChildIndex + 1) {
              (childInput as any).focus();
              return;
            }
          }

          prevInput = childInput;
        },
      );
    } else if (e.key === "ArrowUp") {
      document.getElementById(`input-${id}-${Math.max(0, row.index - 1)}`)?.focus();
    } else if (e.key === "ArrowDown") {
      document.getElementById(`input-${id}-${row.index + 1}`)?.focus();
    }
  };

  const onFocus = () => {
    setEditFocused(true);
    if (value && value.template) {
      setEditableValue(value.template);
    } else {
      setEditableValue(value ? (value.value as string) : "");
    }
  };

  const getDDBType = () => {
    if (value) {
      if (value.template) {
        return compositeStringDDBType;
      } else if (value.type === "boolean") {
        return booleanDDBType;
      } else if (value.type === "number") {
        return numberDDBType;
      } else if (value.type === "map") {
        return mapDDBType;
      } else if (value.type === "string") {
        return stringDDBType;
      }
    }

    return stringDDBType;
  };

  const ddbType = getDDBType();

  return (
    <Flex className={computeStyle()} style={{ overflow: "hidden" }}>
      {value && (value.value !== undefined || value.template) && (
        <>
          <ReactTooltip
            id={`type-${row.index}-${id}`}
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
            placement={"right"}
            overlay={
              <span>
                {ddbType.description} {value.template || ""}
              </span>
            }
          >
            <select
              disabled={props.disableTypeSelector || props.readOnly}
              className="ddb-type-picker"
              style={{
                backgroundColor: ddbType.color,
                borderRadius: "3px",
                padding: "3px",
                marginRight: "2px",
                cursor: "pointer",
              }}
              value={convertToDDBType(value.type)}
              onChange={onChangeType}
            >
              <option>S</option>
              <option>N</option>
              <option>BOOL</option>
              <option>M</option>
            </select>
          </ReactTooltip>
        </>
      )}
      {(ddbType.type === "S" || ddbType.type === "CS" || ddbType.type === "N") && (
        <input
          className="editable-cell-input"
          disabled={props.disableValueEditor || props.readOnly}
          id={`input-${id}-${row.index}`}
          value={isEditFocused ? editableValue : (value?.value as string) || ""}
          onChange={onChange}
          onKeyDown={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{
            color: "black",
            width: ddbType.type === "N" ? "45px" : "initial",
          }}
          ref={inputRef}
          type={ddbType.inputType}
          size={
            !value?.value || (value?.value as string).length === 0
              ? 10
              : (isEditFocused ? (editableValue || "").length + 1 : ((value?.value as string) || "").length + 1) * 1.1
          }
        />
      )}

      {ddbType.type === "BOOL" && (
        <select
          disabled={props.disableValueEditor}
          className="boolean-picker"
          style={{
            borderRadius: "3px",
            padding: "2px 3px",
            marginRight: "2px",
            cursor: props.disableValueEditor ? "not-allowed" : "pointer",
            color: "black",
          }}
          value={(value?.value as boolean).toString().toUpperCase()}
          onChange={(e) => {
            onBoolValueChange(e.target.value);
          }}
        >
          <option value="TRUE">TRUE</option>
          <option value="FALSE">FALSE</option>
        </select>
      )}
      {ddbType.type === "M" && (
        <div
          style={{
            fontFamily: "monospace",
            backgroundColor: "#f5f5f5",
            padding: "2px 3px",
            color: "#555",
            borderRadius: "3px",
            cursor: "pointer",
          }}
          onClick={() => {
            props.setMapAttributesModalState!({
              isOpen: true,
              rowIndex: row.index,
              columnId: id,
              blob: JSON.stringify(editableValue || {}, null, 2),
            });
          }}
        >
          {mapDescString}
        </div>
      )}
    </Flex>
  );
};

export const getTemplateDetails = (template: string, row: Row) => {
  const parts = template.split("#"); // part is in ${something} format
  const correctParts = parts.filter((p) => p.match(/^\$\{([a-zA-Z0-9])+\}/));
  const formattedParts = correctParts.map((p) => p.replace(/\$\{|\}/g, ""));

  const renderedValue = parts
    .map((part) => {
      if (part.match(/^\$\{([a-zA-Z0-9-_])+\}/)) {
        const formattedPart = part.replace(/\$\{|\}/g, "");
        const referencedCell = (row.original as any)[formattedPart]!;

        if (referencedCell) {
          return referencedCell?.value;
        } else {
          return part;
        }
      }

      return part;
    })
    .join("#");

  return {
    isCorrect: true,
    renderedValue,
    formattedParts,
  };
};

export const isTemplateString = (potentialTemplate: string): boolean => {
  return getTemplatedStringParts(potentialTemplate).length > 0;
};

export const getTemplatedStringParts = (template: string) => {
  const parts = template.split("#"); // part is in ${something} format
  const correctParts = parts.filter((p) => p.match(/^\$\{([a-zA-Z0-9-_])+\}/));
  return correctParts;
};

export const getTemplateDetailsFromOriginal = (template: string, original: any) => {
  const parts = template.split("#"); // part is in ${something} format
  const correctParts = getTemplatedStringParts(template);
  const formattedParts = correctParts.map((p) => p.replace(/\$\{|\}/g, ""));

  const renderedValue = parts
    .map((part) => {
      if (part.match(/^\$\{([a-zA-Z0-9-_])+\}/)) {
        const formattedPart = part.replace(/\$\{|\}/g, "");
        const referencedCell = original[formattedPart]!;

        if (referencedCell) {
          return referencedCell?.value;
        } else {
          return part;
        }
      }

      return part;
    })
    .join("#");

  return {
    isCorrect: true,
    renderedValue,
    formattedParts,
  };
};

export default EditableCell;

const mapDescString = `{...}`;
