import { Popover } from "@headlessui/react";
import { Box } from "rebass";
import Button from "./Button";

type ExportAsPopoverProps = {
  onNoSQLWorkbenchModelExport: () => void;
  onOnetableModelExport: () => void;
  onDynamoDBToolboxModelExport: () => void;
};

export const ExportAsPopover = ({
  onNoSQLWorkbenchModelExport,
  onOnetableModelExport,
  onDynamoDBToolboxModelExport,
}: ExportAsPopoverProps) => (
  <Popover.Panel
    style={{
      zIndex: 10002,
      background: "white",
      boxShadow: "5px 5px 10px #ddd",
      position: "absolute",
      padding: "8px",
      border: "1px solid #d5d5d5",
      width: "200px",
    }}
  >
    {({ close }) => (
      <Box>
        <Button m={1} onClick={() => onNoSQLWorkbenchModelExport()}>
          As Dynobase / NoSQL Workbench model
        </Button>
        <Button m={1} onClick={() => onOnetableModelExport()}>
          As dynamodb-onetable model
        </Button>
        <Button m={1} onClick={() => onDynamoDBToolboxModelExport()}>
          As dynamodb-toolbox model
        </Button>
      </Box>
    )}
  </Popover.Panel>
);
