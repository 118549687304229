import { SyntheticEvent } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { Cell } from "react-table";
import { Attribute } from "./App";
import { ContextMenuProps } from "./ContextMenu";
import GenerateValueSubMenu from "./GenerateValueSubMenu";
import { Model } from "./helpers/computeModelsFromState";
import InsertModelMenu from "./InsertModelMenu";

type AttributeContextMenuProps = ContextMenuProps & {
  models: Model[];
  pkName: string;
  duplicateRow: ({ cell }: { cell: Cell }) => void;
  entityTypeAttributeName: string;
  excludeColumn: (columnId: string) => void;
  addPartitionRow: ({
    cell,
    extraData,
  }: {
    cell: Cell;
    extraData?: Record<string, Attribute>;
  }) => void;
  removeAttributeFromAllItems: (
    attributeName: string,
    modelName?: string
  ) => void;
};

const AttributeContextMenu = ({
  updateMyData,
  duplicateRow,
  excludeColumn,
  entityTypeAttributeName,
  removeAttributeFromAllItems,
  models,
  addPartitionRow,
  pkName,
}: AttributeContextMenuProps) => (
  <ContextMenu id="attribute-menu">
    <MenuItem
      onClick={(e: SyntheticEvent, cell: Cell) => {
        duplicateRow({ cell });
      }}
    >
      Duplicate Item
    </MenuItem>
    <MenuItem
      onClick={(e: SyntheticEvent, cell: Cell) => {
        navigator.clipboard.writeText(JSON.stringify(cell.row.values, null, 2));
      }}
    >
      Copy Item
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: undefined,
          type: "string",
          template: "",
        });
      }}
    >
      Empty Attribute
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        removeAttributeFromAllItems(
          cell.column.id,
          (cell.row.original as any)[entityTypeAttributeName].value
        );
      }}
    >
      Remove Attribute from Model
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        excludeColumn(cell.column.id);
      }}
    >
      Hide Attribute
    </MenuItem>
    <GenerateValueSubMenu updateMyData={updateMyData} />
    <InsertModelMenu
      updateMyData={updateMyData}
      models={models}
      entityTypeAttributeName={entityTypeAttributeName}
      addPartitionRow={addPartitionRow}
      pkName={pkName}
    />
  </ContextMenu>
);

export default AttributeContextMenu;
