/* eslint-disable jsx-a11y/anchor-is-valid */
import { MinusCircleIcon } from "@heroicons/react/outline";
import { Row } from "react-table";
import ReactTooltip from "rc-tooltip";
import { Flex } from "rebass";
import EditableCell, { getTemplateDetails } from "../EditableCell";

type SortKeyColumnProps = {
  skName: string;
  removeSk: ({ row }: { row: Row }) => void;
  shouldShowRemoveOption: boolean;
  promptChangingTemplateInAllModelInstances?: (
    columnId: string,
    row: Row,
    newTemplate: string
  ) => void;
  readOnly?: boolean;
};

export const sortKeyColumn = ({
  skName,
  removeSk,
  shouldShowRemoveOption,
  promptChangingTemplateInAllModelInstances,
  readOnly,
}: SortKeyColumnProps) => {
  return {
    Header: skName,
    accessor: skName,
    Cell: ({ ...restOfProps }: any) => {
      let value;

      if (restOfProps.cell.value) {
        if (restOfProps.cell.value.template) {
          const { renderedValue } = getTemplateDetails(
            restOfProps.cell.value.template,
            restOfProps.row
          );
          value = renderedValue;
        } else {
          value = restOfProps.cell.value.value;
        }
      }

      return (
        <Flex justifyContent="space-between">
          <EditableCell
            value={restOfProps.cell.value}
            row={restOfProps.row}
            column={restOfProps.column}
            updateMyData={restOfProps.updateMyData}
            disableTypeSelector
            readOnly={readOnly}
            promptChangingTemplateInAllModelInstances={
              promptChangingTemplateInAllModelInstances
            }
          />
          {restOfProps.cell.value && !readOnly && (
            <div className="pointer">
              <ReactTooltip
                id={`removeRow-${value}`}
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0}
                placement={"right"}
                overlay={
                  <span>
                    Remove item with {skName} = {value}{" "}
                  </span>
                }
              >
                <MinusCircleIcon
                  id="remove-item"
                  width={16}
                  height={16}
                  className="sk-remove-btn"
                  onClick={() => {
                    removeSk(restOfProps);
                  }}
                  color="#bbb"
                />
              </ReactTooltip>
            </div>
          )}
        </Flex>
      );
    },
  };
};
