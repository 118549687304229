import React, { useState } from "react";
import Form from "@rjsf/core";
import SettingsSchema from "./forms/Settings";
import ModalBase from "./ModalBase";
import Button from "./Button";
import { SaveAsIcon } from "@heroicons/react/outline";
import { Flex } from "rebass";
import { getSettings, setSetting } from "./helpers/settings";

type SettingsModalProps = {
  isVisible: boolean;
  onClose: () => void;
  setTableStyle: (style: string) => void;
};

const SettingsModal = (props: SettingsModalProps) => {
  const formRef = React.useRef(null);
  const [state, setState] = useState(getSettings());

  return (
    <ModalBase
      onClose={props.onClose}
      modalTitle={"Settings"}
      isVisible={props.isVisible}
      content={() => (
        <div>
          <Form
            className="settings-modal rjsf"
            schema={SettingsSchema as any}
            formData={state}
            showErrorList={false}
            onChange={(e: any) => setState(e.formData)}
            onError={console.error}
            liveValidate
            ref={formRef}
            uiSchema={{
              expression: {
                "ui:widget": "hidden",
              },
            }}
          />
        </div>
      )}
      footer={() => (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          style={{ borderTop: "1px solid #e4e4e4" }}
          p={3}
        >
          <Button
            icon={() => (
              <SaveAsIcon
                width={16}
                height={16}
                color={"#fff"}
                style={{
                  marginBottom: -3,
                }}
              />
            )}
            className="button-save-item"
            style={{
              padding: "10px",
              backgroundColor: "#4997f7",
              marginLeft: "20px",
              color: "white",
            }}
            color="white"
            onClick={() => {
              setSetting(
                "entityTypeAttributePosition",
                state.entityTypeAttributePosition
              );
              setSetting(
                "attributesTruncateLength",
                state.attributesTruncateLength
              );
              setSetting("showModelOutput", state.showModelOutput);
              setSetting(
                "singleTableDesignerStyle",
                state.singleTableDesignerStyle
              );
              props.onClose();
            }}
          >
            Save
          </Button>
        </Flex>
      )}
    />
  );
};

export default SettingsModal;
