import React, { useState } from "react";
import Form from "@rjsf/core";
import NewModel from "./forms/NewModel";
import ModalBase from "./ModalBase";
import Button from "./Button";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { Flex } from "rebass";

type NewModelModalProps = {
  isVisible: boolean;
  onClose: () => void;
  newModel: (model: any) => void;
};

const NewModelModal = (props: NewModelModalProps) => {
  const formRef = React.useRef(null);
  const [state, setState] = useState({
    modelName: "",
    partitionKey: "",
    sortKey: "",
    entityTypeAttributeName: "",
  });

  return (
    <ModalBase
      onClose={props.onClose}
      modalTitle={"New Model"}
      isVisible={props.isVisible}
      content={() => (
        <div>
          <Form
            className="new-model-modal rjsf"
            schema={NewModel as any}
            formData={state}
            showErrorList={false}
            onChange={(e: any) => setState(e.formData)}
            onError={console.error}
            liveValidate
            ref={formRef}
            uiSchema={{
              expression: {
                "ui:widget": "hidden",
              },
            }}
          />
        </div>
      )}
      footer={() => (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          style={{ borderTop: "1px solid #e4e4e4" }}
          p={3}
        >
          <Button
            icon={() => (
              <PlusCircleIcon
                width={16}
                height={16}
                color={"#fff"}
                style={{
                  marginBottom: -3,
                }}
              />
            )}
            className="button-save-item"
            style={{
              padding: "10px",
              backgroundColor:
                formRef &&
                formRef.current &&
                (formRef.current as any).state.errors.length !== 0
                  ? "rgb(158, 158, 158)"
                  : "#4997f7",
              color: "white",
            }}
            color="white"
            disabled={
              !!(
                formRef &&
                formRef.current &&
                (formRef.current as any).state.errors.length !== 0
              )
            }
            onClick={() => {
              props.newModel({
                ...state,
              });
              props.onClose();
            }}
          >
            New Model
          </Button>
        </Flex>
      )}
    />
  );
};

export default NewModelModal;
