import React from "react";
import { Box, Text } from "rebass";
import { Checkbox, Label } from "@rebass/forms";

interface ExcludedAttributesMenuProps {
  excludedAttributes?: string[];
  attributesList?: string[];
  setExcluded: (excludedColumns: string[]) => void;
}

const ExcludedAttributesMenu: React.FC<ExcludedAttributesMenuProps> = (
  props: ExcludedAttributesMenuProps
) => {
  const toggleExcluded = (excluded: string) => {
    const isFound = (props.excludedAttributes || []).find(
      (e) => e === excluded
    );

    if (isFound) {
      props.setExcluded(
        (props.excludedAttributes || []).filter((e) => e !== excluded)
      );
    } else {
      props.setExcluded([...(props.excludedAttributes || []), excluded]);
    }
  };

  return (
    <Box
      p={2}
      className="shadow"
      style={{
        backgroundColor: "#f8f8f8",
        position: "absolute",
        borderRadius: "4px",
        top: "-5px",
        left: "8px",
        zIndex: 9998,
        overflow: "scroll",
        maxHeight: "75vh",
      }}
    >
      <Text fontWeight={400} color="535353" mb={2}>
        Excluded Attributes
      </Text>

      {(props.attributesList || [])
        // .slice(1, (props.attributesList || []).length - 1)
        .map((attribute) => (
          <Label key={attribute} width={1} px={2} style={{ cursor: "pointer" }}>
            <Checkbox
              id={attribute}
              name={attribute}
              className="rebass-checkbox"
              onChange={() => toggleExcluded(attribute)}
              checked={
                props.excludedAttributes &&
                props.excludedAttributes.find((e) => e === attribute)
              }
            />
            {attribute}
          </Label>
        ))}
    </Box>
  );
};

export default ExcludedAttributesMenu;
