import { useState } from "react";

export function useForceUpdate() {
  const [dep, setValue] = useState<number>(0);

  return {
    forceUpdate: () => setValue((value) => value + 1),
    dep,
  };
}
