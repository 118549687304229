import { State } from "../App";
import EditableCell from "../EditableCell";
import {
  computeModelsFromState,
  Model,
} from "../helpers/computeModelsFromState";

export const modelColumns = (state: State, entityTypeAttributeName: string) => {
  const models = computeModelsFromState(state, entityTypeAttributeName);
  const attributes = getNonEntityTypeAttributes(
    models,
    entityTypeAttributeName
  );

  const columns = [
    {
      id: "attributes",
      Header: "Attributes",
      columns: [
        {
          accessor: entityTypeAttributeName,
          id: entityTypeAttributeName,
          Header: "Type",
          Cell: (row: any) => {
            return row.value.value || "nothing";
          },
        },
        ...attributes,
      ].filter(
        (col) =>
          !state.excludedColumns ||
          state.excludedColumns.length === 0 ||
          !state.excludedColumns.includes(col.accessor)
      ),
    },
  ];

  return columns;
};

const getNonEntityTypeAttributes = (
  models: Model[],
  entityTypeAttributeName: string
) => {
  const attributes: any[] = [];

  models.forEach((model) => {
    Object.entries(model.attributes).forEach(([key, value]) => {
      if (
        attributes.find((a) => a.accessor === key) ||
        key === entityTypeAttributeName
      ) {
        return;
      }

      attributes.push({
        accessor: key,
        Header: key,
        canGroupBy: false,
        Cell: (row: any) => {
          if (row.value) {
            return (
              <EditableCell
                value={row.value}
                row={row.row}
                column={row.column}
                updateMyData={() => {}}
                disableTypeSelector
                disableValueEditor
              />
            );
          } else {
            return <div></div>;
          }
        },
      });
    });
  });

  return attributes;
};
