import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import toast from "react-hot-toast";
import { Cell, Row } from "react-table";
import { Flex, Text } from "rebass";
import { Attribute } from "../App";
import EditableCell from "../EditableCell";
import Button from "../Button";

type OtherAttributesColumnProps = {
  entityTypeAttributeName: string;
  nonAnyKeyAttributesList: string[];
  updateMyData: any;
  setMapAttributesModalState?: (arg: any) => void;
  addAttributeToAllInstancesOfModel: (attributeName: string, modelName: string) => void;
  readOnly?: boolean;
};

export const OtherAttributesListColumn = ({
  entityTypeAttributeName,
  nonAnyKeyAttributesList,
  updateMyData,
  setMapAttributesModalState,
  addAttributeToAllInstancesOfModel,
  readOnly,
}: OtherAttributesColumnProps) => {
  return {
    Header: "Other Attributes",
    Cell: ({ row, cell }: { row: Row; cell: Cell }) => {
      const [isAddingNewAttribute, setIsAddingNewAttribute] = useState(false);
      const [newAttributeName, setNewAttributeName] = useState("");
      const modelName = (row.original as any)[entityTypeAttributeName]?.value;

      const onSubmitNewAttribute = () => {
        if (newAttributeName.length < 2) {
          return;
        }

        setIsAddingNewAttribute(false);
        updateMyData(row.index, newAttributeName, {
          type: "string",
          value: "~new~",
        } as Attribute);

        toast(
          (t) => (
            <span>
              <Text mb={1}>
                Do you want to add attribute "{newAttributeName}" to all instances of the "{modelName}" model?
              </Text>
              <Button
                onClick={() => {
                  addAttributeToAllInstancesOfModel(newAttributeName, modelName);

                  toast.dismiss(t.id);
                }}
              >
                Yes, add to all instances
              </Button>
            </span>
          ),
          {
            duration: 10000,
          },
        );
      };

      return (
        <Flex flexDirection="row">
          {Object.entries(row.original)
            .filter(([key]) => key !== "__id")
            .filter(([key]) => nonAnyKeyAttributesList.includes(key))
            .filter(([key]) => key !== entityTypeAttributeName)
            .filter(([key, value]) => value && ((value as Attribute).value || (value as Attribute).template))
            .map(([key, value]) => {
              return (
                <ContextMenuTrigger
                  key={`${cell.column.id}-${key}-${cell.row.id}`}
                  attributes={
                    {
                      role: "cell",
                      rowSpan: (cell as any).rowSpan,
                    } as any
                  }
                  id={"attribute-menu"}
                  collect={() => ({
                    ...cell,
                    column: {
                      id: key,
                    },
                  })}
                >
                  <div
                    style={{
                      margin: "-0.25rem",
                      width: "150px",
                      maxWidth: "150px",
                      overflow: "hidden",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    <Text
                      sx={{
                        textAlign: "center",
                        background: "#f5f5f5",
                        width: "100%",
                      }}
                    >
                      {key}
                    </Text>
                    <div style={{ margin: "0.25rem", marginLeft: "0.65rem" }}>
                      <EditableCell
                        value={(row.original as Record<string, Attribute>)[key] as Attribute}
                        row={row}
                        column={{ id: key }}
                        readOnly={readOnly}
                        updateMyData={updateMyData}
                        setMapAttributesModalState={setMapAttributesModalState}
                      />
                    </div>
                  </div>
                </ContextMenuTrigger>
              );
            })}

          {!isAddingNewAttribute ? (
            <Flex
              style={{
                marginLeft: "6px",
                height: "36px",
                display: readOnly ? "none" : "flex",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <PlusCircleIcon
                color="gray"
                width={16}
                height={16}
                onClick={() => {
                  setIsAddingNewAttribute(true);
                  setNewAttributeName("");
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </Flex>
          ) : (
            <Flex style={{ alignContent: "center", justifyContent: "center" }}>
              <input
                autoFocus
                placeholder="Attribute Name"
                style={{
                  backgroundColor: "white",
                  marginLeft: "8px",
                }}
                onChange={(e) => {
                  setNewAttributeName(e.target.value);
                }}
                value={newAttributeName}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onSubmitNewAttribute();
                  }
                }}
                onBlur={() => onSubmitNewAttribute()}
              />
              {!readOnly && (
                <MinusCircleIcon
                  width={16}
                  height={16}
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  onClick={() => setIsAddingNewAttribute(false)}
                  color="#bbb"
                />
              )}
            </Flex>
          )}
        </Flex>
      );
    },
    maxWidth: "160px",
    accessor: "__attributes__",
  };
};
