import { State, Attribute } from "./App";
import {
  IndexProjection,
  Model,
  DynamoDBString,
  ItemModel,
  QueryOperation,
  FilterOperation,
} from "./types/Model";
import { v4 } from "uuid";
import { convertFromDDBType } from "./helpers/convertFromDDBJSON";
import { isTemplateString } from "./EditableCell";
import { clearQueries, saveQuery } from "./Query";
import { AttributeType, StringOperator } from "./FilterExpression";

export const importModel = (model: Model): State => {
  // todo - validation using something like ajv to return human readable error

  const indexes = computeIndexes(model);

  const primaryKey = indexes.find((i) => i.isSelected);
  const partitionKey = primaryKey?.hash!;
  // eslint-disable-next-line
  const sortKey = primaryKey?.sort!;

  const allAttributes: string[] = [];

  model.DataModel[0].TableData.forEach((record) => {
    const recordAttributes = Object.keys(record);
    allAttributes.push(...recordAttributes);
  });

  const data = model.DataModel[0].TableData.map((record) => {
    const normalizedRecord: Record<string, Attribute> = {};
    const modelTypeField = model.ModelSchema?.params?.typeField ?? "type";
    const modelType = (record[modelTypeField] as DynamoDBString)?.S;
    let relatedModel: ItemModel | undefined;

    if (modelType) {
      relatedModel = model.ModelSchema?.models[modelType];
    }

    Object.entries(record).forEach(([key, value]) => {
      const valueCopy = JSON.parse(JSON.stringify(value));

      // const template = (valueCopy as any).template;
      // template should be taken from ModelSchema instead
      let template;
      if (
        relatedModel &&
        relatedModel[key] &&
        relatedModel[key].value &&
        isTemplateString(relatedModel[key].value!)
      ) {
        template = relatedModel[key].value;
      }

      /**
       * Convert "key": { "S": "value" },
       * to "key": "value"
       */
      normalizedRecord[key] = {
        value: Object.values(valueCopy)[0],
        type: convertFromDDBType(Object.keys(valueCopy)[0] as any),
        template,
      };
    });
    normalizedRecord.__id = v4() as any;
    return normalizedRecord;
  });

  console.log("Data from import", data);

  const dataSortedByPk = data.sort((a, b) => {
    const aPk = a[partitionKey].value as string;
    const bPk = b[partitionKey].value as string;
    if (aPk < bPk) {
      return -1;
    }
    if (aPk > bPk) {
      return 1;
    }
    return 0;
  });

  // toast.success("Model imported successfully.");

  importQueries(model);

  return {
    modelName: model.ModelName,
    indexes,
    data: dataSortedByPk,
    excludedColumns: [],
  };
};

const importQueries = (model: Model) => {
  if (!model.ModelSchema) {
    return;
  }

  const { queries } = model.ModelSchema;

  if (!queries) {
    return;
  }

  clearQueries(model.ModelName);

  Object.entries(queries).forEach(([queryName, queryDefinition]) => {
    saveQuery(model.ModelName, {
      name: queryName,
      pkValue: queryDefinition.hash,
      currentIndex: queryDefinition.index,
      skValue: queryDefinition.sort,
      skOperator: QueryOperationToSkOperator(queryDefinition.operation),
      filterExpressions: (queryDefinition.filters || []).map((filter) => ({
        id: generateRandomId(4),
        attributeName: filter.field,
        attributeValue: filter.value,
        operator: OperationToFilterOperator(filter.operation),
        attributeType: AttributeType.String,
        logicalEvaluation: "AND",
        createdAt: +new Date(),
      })),
    });
  });
};

const QueryOperationToSkOperator = (
  operation: QueryOperation
): StringOperator => {
  switch (operation) {
    case "Equal":
      return StringOperator.Equal;
    case "Begins with":
      return StringOperator.BeginsWith;
    case "Less than":
      return StringOperator.LessThan;
    case "Less than or equal":
      return StringOperator.LessEqualThan;
    case "Greater than or equal":
      return StringOperator.GreaterEqualThan;
    case "Greater than":
      return StringOperator.GreaterThan;
    default:
      return StringOperator.Equal;
  }
};

const OperationToFilterOperator = (
  operation: FilterOperation
): StringOperator => {
  switch (operation) {
    case "Equal":
      return StringOperator.Equal;
    case "Begins with":
      return StringOperator.BeginsWith;
    case "Less than":
      return StringOperator.LessThan;
    case "Less than or equal":
      return StringOperator.LessEqualThan;
    case "Greater than or equal":
      return StringOperator.GreaterEqualThan;
    case "Greater than":
      return StringOperator.GreaterThan;
    case "Contains":
      return StringOperator.Contains;
    case "Does not contain":
      return StringOperator.NotContains;
    case "Existing":
      return StringOperator.Exists;
    case "Not Existing":
      return StringOperator.NotExists;
    default:
      return StringOperator.Equal;
  }
};

// In Mobile Payments Service.json ModelSchema is not defined.
// We need to join indexes from DataModel.KeyAttributes and DataModel.GlobalSecondaryIndexes
const computeIndexes = (model: Model) => {
  if (model.ModelSchema) {
    const indexes = Object.entries(model.ModelSchema.indexes || []).map(
      ([key, value]) => ({
        hash: value.hash,
        sort: value.sort,
        name: key,
        isSelected: key === "primary",
        projection: "ALL" as IndexProjection, // todo - import actual projection
      })
    );

    return indexes;
  } else if (model.DataModel[0].KeyAttributes) {
    const keyAttributes = model.DataModel[0].KeyAttributes;
    const indexes = [];

    indexes.push({
      hash: keyAttributes.PartitionKey.AttributeName,
      sort: keyAttributes.SortKey.AttributeName,
      name: "primary",
      isSelected: true,
    });

    (model.DataModel[0].GlobalSecondaryIndexes || []).forEach((gsi) => {
      indexes.push({
        hash: gsi.KeyAttributes.PartitionKey.AttributeName,
        sort: gsi.KeyAttributes.SortKey.AttributeName,
        name: gsi.IndexName,
        isSelected: false,
        projection: gsi.Projection.ProjectionType,
        // todo projectedAttributes: gsi.Projection
      });
    });

    return indexes;
  }

  return [];
};

const generateRandomId = (repetitions = 1) => {
  let randomId = "";
  [...Array(repetitions)].forEach((repetition) => {
    randomId += Math.random().toString(36).substring(2, 15);
  });
  return randomId;
};
