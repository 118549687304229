import { SyntheticEvent } from "react";
import { MenuItem, SubMenu } from "react-contextmenu";
import { Cell } from "react-table";
import { Attribute } from "./App";
import { ContextMenuProps } from "./ContextMenu";
import { Model } from "./helpers/computeModelsFromState";

type InsertModelMenuProps = ContextMenuProps & {
  pkName: string;
  entityTypeAttributeName: string;
  models: Model[];
  addPartitionRow: ({
    cell,
    extraData,
  }: {
    cell: Cell;
    extraData?: Record<string, Attribute>;
  }) => void;
};

const InsertModelMenu = ({
  addPartitionRow,
  models,
  pkName,
  entityTypeAttributeName,
}: InsertModelMenuProps) => (
  <SubMenu title="Insert Model Instance">
    {models.map((model) => (
      <MenuItem
        key={`model-${model.type}`}
        onClick={(_: SyntheticEvent, cell: Cell) => {
          const extraData: Record<string, Attribute> = {};
          const partitionKey = ((cell.row as any).original as any)[pkName];

          Object.entries(model.attributes).forEach(([key, value]) => {
            if (key === pkName) {
              return;
            }

            extraData[key] = {
              type: value.type,
              value: value.default || "~new~",
              template: value.template,
            };
          });

          extraData[entityTypeAttributeName] = {
            value: model.type,
            type: "string",
          };

          addPartitionRow({
            cell: {
              value: partitionKey,
            } as any,
            extraData,
          });
        }}
      >
        {model.type}
      </MenuItem>
    ))}
  </SubMenu>
);

export default InsertModelMenu;
