/* eslint-disable jsx-a11y/anchor-is-valid */
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { Column, Row } from "react-table";
import ReactTooltip from "rc-tooltip";
import { Flex } from "rebass";
import EditableCell from "../EditableCell";

type AttributesColumnProps = {
  att: string;
  isLast: boolean;
  areAttributesGroupped: boolean;
  entityTypeAttributeName: string;
  setIsAddingNewColumn: any;
  removeAttributeFromAllItems: (attributeName: string) => void;
  setMapAttributesModalState?: (arg: any) => void;
  checkIfEnteringExistingModelInstance: (
    potentialModelName: string,
    columnName: string,
    row: Row
  ) => void;
  notRemovable?: boolean;
  readOnly?: boolean;
};

export const attributesColumn = ({
  att,
  isLast,
  areAttributesGroupped,
  entityTypeAttributeName,
  setIsAddingNewColumn,
  removeAttributeFromAllItems,
  setMapAttributesModalState,
  checkIfEnteringExistingModelInstance,
  notRemovable,
  readOnly,
}: AttributesColumnProps) => {
  return {
    Cell: ({ ...restOfProps }: any) => (
      <EditableCell
        readOnly={readOnly}
        value={restOfProps.cell.value}
        row={restOfProps.row}
        column={restOfProps.column}
        updateMyData={restOfProps.updateMyData}
        setMapAttributesModalState={setMapAttributesModalState}
        checkIfEnteringExistingModelInstance={
          checkIfEnteringExistingModelInstance
        }
      />
    ),
    shouldBeRendered: () => {
      if (att === entityTypeAttributeName) return true;

      if (areAttributesGroupped) {
        return false;
      }

      return true;
    },
    Header: ({ column }: { column: Column }) => {
      return (
        <Flex>
          <span style={{ marginTop: "2px", marginRight: "2px" }}>{att}</span>
          {!readOnly && (
            <ReactTooltip
              id={`removeCol-${att}`}
              mouseEnterDelay={0.5}
              mouseLeaveDelay={0}
              overlay={<span>Remove attribute "{att}" from all items</span>}
            >
              <MinusCircleIcon
                width={16}
                height={16}
                style={{
                  cursor: "pointer",
                  display: !notRemovable ? "block" : "none",
                }}
                onClick={() => removeAttributeFromAllItems(column.id!)}
                color="#bbb"
              />
            </ReactTooltip>
          )}
          {!readOnly && isLast && (
            <PlusCircleIcon
              width={16}
              height={16}
              style={{ cursor: "pointer" }}
              onClick={() => setIsAddingNewColumn((is: boolean) => !is)}
              color="#bbb"
            />
          )}
        </Flex>
      );
    },
    accessor: att,
  };
};
