import camelcase from "camelcase";
import { format } from "prettier/standalone";
import babel from "prettier/parser-babel";
import { State } from "../App";
import {
  computeModelsFromState,
  enrichModelWithReferentialInfo,
  ModelAttributeWithReferenceInfo,
} from "./computeModelsFromState";

const prefix = `import { Table, Entity } from "dynamodb-toolbox";
import DynamoDB from "aws-sdk/clients/dynamodb";

const DocumentClient = new DynamoDB.DocumentClient();`;

export const generateDynamoDBToolboxCode = (
  state: State,
  entityTypeAttributeName: string
) => {
  const code = `${prefix}

${generateTableCode(state)}

${generateEntities(state, entityTypeAttributeName)}
  `;

  return format(code, { semi: false, parser: "babel", plugins: [babel] });
};

const generateTableCode = (state: State) => {
  const modelName = camelcase(state.modelName);

  return `const ${modelName} = new Table({
  name: "${modelName}-table",

  partitionKey: "${state.indexes[0].hash}",
  sortKey: "${state.indexes[0].sort}",

  indexes: ${JSON.stringify(
    state.indexes.map((index) => ({
      partitionKey: index.hash,
      sortKey: index.sort,
    })),
    null,
    2
  )},

  DocumentClient,
});`;
};

const generateEntities = (state: State, entityTypeAttributeName: string) => {
  const modelName = camelcase(state.modelName);
  const models = computeModelsFromState(state, entityTypeAttributeName).map(
    (m) => enrichModelWithReferentialInfo(m)
  );

  console.log(models);

  return models
    .map(
      (model) => `
const ${capitalizeFirstLetter(model.type)} = new Entity({
  name: "${model.type}",

  attributes: { ${Object.keys(model.attributes)
    .map(
      (att) => `
    ${att}: ${computeAttributeCode(att, model.attributes[att]!, state)}`
    )
    .join(",")}
  },

  table: ${modelName}
});
`
    )
    .join("\n");
};

const computeAttributeCode = (
  attributeName: string,
  attribute: ModelAttributeWithReferenceInfo,
  state: State
) => {
  const isPk = attributeName === state.indexes[0].hash;
  const isSk = attributeName === state.indexes[0].sort;
  const type = attribute.type;

  const attr: any = {
    type,
  };

  if (isPk) {
    attr.partitionKey = true;
  }
  if (isSk) {
    attr.sortKey = true;
  }

  // if it is being used as composite
  if (attribute.reference) {
    return JSON.stringify([
      attribute.reference.from,
      attribute.reference.position,
    ]);
  }

  return JSON.stringify(attr);
};

function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
