/* eslint-disable jsx-a11y/anchor-is-valid */
import { KeyIcon, MinusCircleIcon } from "@heroicons/react/outline";
import { Column } from "react-table";
import ReactTooltip from "rc-tooltip";
import { Flex } from "rebass";
import { Index } from "../App";
import EditableCell from "../EditableCell";
import { ContextMenuTrigger } from "react-contextmenu";

type OtherIndexColumnProps = {
  index: Index;
  excludedColumns: string[];
  removeAttributeFromAllItems: (columnId: string) => void;
  onClick: (...args: any[]) => void;
  readOnly?: boolean;
};

export const otherIndexColumn = ({
  index,
  removeAttributeFromAllItems,
  excludedColumns,
  onClick,
  readOnly,
}: OtherIndexColumnProps) => {
  return {
    Header: () => (
      <ContextMenuTrigger
        key={`index-${index.name}`}
        attributes={
          {
            index,
          } as any
        }
        id={"gsi-menu"}
        collect={() => ({
          ...index,
        })}
      >
        <div
          onClick={onClick}
          style={{ cursor: "pointer" }}
          className="otherColumnHeader"
        >
          <KeyIcon
            width={12}
            height={12}
            color="gray"
            style={{ marginBottom: "-1px", marginRight: "2px" }}
          />
          {index.name}
        </div>
      </ContextMenuTrigger>
    ),
    id: index.name,
    columns: [
      (excludedColumns || []).includes(index.hash)
        ? undefined
        : {
            accessor: index.hash,
            Cell: ({ ...restOfProps }: any) => (
              <EditableCell
                value={restOfProps.cell.value}
                row={restOfProps.row}
                column={restOfProps.column}
                updateMyData={restOfProps.updateMyData}
                disableTypeSelector
                readOnly={readOnly}
              />
            ),
            Header: ({ column }: { column: Column }) => {
              return (
                <Flex>
                  <span style={{ marginTop: "2px", marginRight: "2px" }}>
                    {index.hash}
                  </span>
                  {!readOnly && (
                    <ReactTooltip
                      id={`removeCol-${index.hash}`}
                      mouseLeaveDelay={0}
                      mouseEnterDelay={0.5}
                      placement="right"
                      overlay={
                        <span>
                          Remove attribute "{index.hash}" from all items
                        </span>
                      }
                    >
                      <MinusCircleIcon
                        width={16}
                        height={16}
                        style={{ cursor: "pointer" }}
                        onClick={() => removeAttributeFromAllItems(column.id!)}
                        color="#bbb"
                      />
                    </ReactTooltip>
                  )}
                </Flex>
              );
            },
          },
      (excludedColumns || []).includes(index.sort)
        ? undefined
        : {
            accessor: index.sort,
            Cell: ({ ...restOfProps }: any) => (
              <EditableCell
                value={restOfProps.cell.value}
                row={restOfProps.row}
                column={restOfProps.column}
                updateMyData={restOfProps.updateMyData}
                disableTypeSelector
                readOnly={readOnly}
              />
            ),
            Header: ({ column }: { column: Column }) => {
              return (
                <Flex>
                  <span style={{ marginTop: "2px", marginRight: "2px" }}>
                    {index.sort}
                  </span>

                  {!readOnly && (
                    <ReactTooltip
                      id={`removeCol-${index.sort}`}
                      mouseLeaveDelay={0}
                      mouseEnterDelay={0.5}
                      placement="right"
                      overlay={
                        <span>
                          Remove attribute "{index.sort}" from all items
                        </span>
                      }
                    >
                      <MinusCircleIcon
                        width={16}
                        height={16}
                        style={{ cursor: "pointer" }}
                        onClick={() => removeAttributeFromAllItems(column.id!)}
                        color="#bbb"
                      />
                    </ReactTooltip>
                  )}
                </Flex>
              );
            },
          },
    ].filter(Boolean),
  };
};
