import { useState } from "react";
import { Cell, TableInstance, useTable } from "react-table";
import Tour from "reactour";
import { Flex, Text } from "rebass";
import { getSetting } from "./helpers/settings";
import TableRow from "./Row";
import { singleTableDesignerIntoSteps } from "./tutorialSteps";

function useInstance(instance: any) {
  const { allColumns } = instance;
  let rowSpanHeaders: any[] = [];
  const column = allColumns[0];
  const { id, enableRowSpan } = column;

  if (enableRowSpan !== undefined) {
    rowSpanHeaders = [
      ...rowSpanHeaders,
      { id, topCellValue: null, topCellIndex: 0 },
    ];
  }

  Object.assign(instance, { rowSpanHeaders });
}

export function Table({
  columns,
  data,
  updateMyData,
  updateRow,
  skName,
  pkName,
  currentView,
  isSearchActive,
  tableStyle,
  isEmptyState,
  readOnly,
}: any) {
  const [isTourOpen, setTourOpen] = useState(
    !window.localStorage.getItem("SHOW_SINGLE_TABLE_DESIGNER_TUTORIAL")
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    rowSpanHeaders,
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      updateRow,
      autoResetSortBy: false,
      initialState: {
        sortBy: [pkName, skName],
      },
    } as any,
    // useSortBy,
    (hooks) => {
      hooks.useInstance.push(useInstance);
    }
  ) as TableInstance<{}> & { rowSpanHeaders: any[]; updateMyData: any };

  const colsUniqueReconciliator = columns[2]
    ? columns[2].columns.map((c: any) => c.accessor).join("__")
    : "nothing";

  return (
    <div
      id="table"
      className={getSetting("singleTableDesignerStyle")}
      style={{
        width: "100%",
      }}
    >
      {rows.length === 0 && isSearchActive && (
        <Flex
          p={2}
          width="100%"
          className="single-table-designer-no-items-found"
        >
          <Text>
            No items matching your query criteria. Try adjusting your query or
            add an item satisfying it.
          </Text>
        </Flex>
      )}

      {!isEmptyState && !readOnly && (
        <Tour
          steps={singleTableDesignerIntoSteps}
          isOpen={isTourOpen}
          onRequestClose={() => {
            setTourOpen(false);
            window.localStorage.setItem(
              "SHOW_SINGLE_TABLE_DESIGNER_TUTORIAL",
              "0"
            );
          }}
        />
      )}

      {(rows.length > 0 || (rows.length === 0 && !isSearchActive)) && (
        <table {...(getTableProps() as any)}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...(headerGroup.getHeaderGroupProps() as any)}>
                {headerGroup.headers
                  .filter((h) => h.id !== "__id")
                  .map((column: any) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
              </tr>
            ))}
          </thead>
          <tbody {...(getTableBodyProps() as any)}>
            {rows.map((row, i) => {
              prepareRow(row);

              for (let j = 0; j < row.allCells.length; j++) {
                let cell = row.allCells[j] as Cell<{}> & {
                  isRowSpanned: boolean;
                  rowSpan: number;
                };
                let rowSpanHeader = rowSpanHeaders.find(
                  (x) => x.id === cell.column.id
                );

                if (rowSpanHeader !== undefined) {
                  if (
                    rowSpanHeader.topCellValue === null ||
                    rowSpanHeader.topCellValue !== cell.value.value
                  ) {
                    cell.isRowSpanned = false;
                    rowSpanHeader.topCellValue = cell.value.value;
                    rowSpanHeader.topCellIndex = i;
                    cell.rowSpan = 1;
                  } else {
                    (rows[rowSpanHeader.topCellIndex].allCells[j] as any)
                      .rowSpan++;
                    cell.isRowSpanned = true;
                  }
                }
              }
              return null;
            })}
            {rows.map((row, ...restOfProps) => {
              return (
                <TableRow
                  key={row.id}
                  currentView={currentView}
                  rowProps={
                    {
                      ...row.getRowProps(),
                      partitionrowspan: (row.cells[0] as any).rowSpan, // force first row from the partition to reconcile
                    } as any
                  }
                  colsUniqueReconciliator={colsUniqueReconciliator}
                  originalRow={row.original}
                  pkName={pkName}
                  skName={skName}
                  cells={row.cells}
                  isHighlighted={(row.original as any).__highlighted}
                  isPartitionHighlighted={(row.original as any).__highlightedPk}
                />
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
