import React, { useState } from "react";
import { Box } from "rebass";
import { Input } from "@rebass/forms";
import Autosuggest from "react-autosuggest";
import { useEffect } from "react";

interface AutocompleteInputProps {
  value: string;
  placeholder: string;
  autocompleteValues: string[];
  style: any;
  onChange: (value: string) => void;
}

const renderInput = (props: any) => <Input {...props} />;

const renderSuggestion = (suggestion: string, { isHighlighted }: any) => (
  <Box
    style={{
      backgroundColor: isHighlighted ? "#4997f7" : "#fff",
      color: isHighlighted ? "white" : "black",
      padding: "5px 10px",
    }}
  >
    {suggestion}
  </Box>
);

const getSuggestions = (value: string, possibleSuggestions: string[]) =>
  possibleSuggestions.filter((s) => (s || "").includes(value));

const AutocompleteInput = (props: AutocompleteInputProps) => {
  const [value, setValue] = useState(props.value || "");
  const [suggestions, setSuggestions] = useState<string[]>([]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e: any, { newValue }: any) => {
    setValue(newValue || e.target.value);
    props.onChange(newValue || e.target.value);
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestions(getSuggestions(value, props.autocompleteValues));
  };

  const inputProps = {
    placeholder: props.placeholder,
    value,
    onChange,
    style: props.style,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInput}
      inputProps={inputProps}
    />
  );
};

export default AutocompleteInput;
