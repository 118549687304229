import { Cell, Row } from "react-table";
import { convertToDDBJSON } from "../helpers/convertToDDBJSON";

type OtherAttributesColumnProps = {
  entityTypeAttributeName: string;
  nonAnyKeyAttributesList: string[];
  setAttributesModalState: (state: any) => void;
  blobTruncateLimit: number;
  readOnly?: boolean;
};

export const otherAttributesColumn = ({
  entityTypeAttributeName,
  nonAnyKeyAttributesList,
  setAttributesModalState,
  blobTruncateLimit,
  readOnly,
}: OtherAttributesColumnProps) => {
  return {
    Header: "Other Attributes",
    Cell: ({ row, cell }: { row: Row; cell: Cell }) => {
      const ddbJson = convertToDDBJSON(row.original, nonAnyKeyAttributesList, [
        entityTypeAttributeName,
      ]);
      const ddbJsonString = JSON.stringify(ddbJson);

      return (
        <div
          style={{
            cursor: "pointer",
            fontFamily: "monospace",
            color: "#333",
            letterSpacing: "-0.3px",
          }}
          onClick={() => {
            setAttributesModalState((s: any) => ({
              ...s,
              isOpen: true,
              blob: JSON.stringify(ddbJson, null, 2),
              rowIndex: row.index,
              columnId: cell.column.id,
            }));
          }}
        >
          {ddbJsonString.substring(0, blobTruncateLimit)}
          {ddbJsonString.length > blobTruncateLimit ? `... }` : ""}
        </div>
      );
    },
    maxWidth: "160px",
    accessor: "__attributes__",
  };
};
