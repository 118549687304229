import { memo, useCallback } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import { Cell, TableRowProps } from "react-table";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import useWhyDidYouUpdate from "./hooks/useWhyDidYouUpdate";

type RowProps = {
  rowProps: TableRowProps;
  originalRow: Record<string, unknown>;
  cells: Cell[];
  pkName: string;
  skName: string;
  colsUniqueReconciliator?: string;
  currentView: string;
  isHighlighted: boolean;
  isPartitionHighlighted: boolean;
};

// If one of the spanned rows has been removed, update this one too!
// Something like rowsSpanned per partition should be added to each row
const areEqual = (prevProps: RowProps, nextProps: RowProps) => {
  const areRowCellValuesEqual =
    prevProps.cells.map((c) => c?.value?.value ?? "__").join("#") ===
    nextProps.cells.map((c) => c?.value?.value ?? "__").join("#");

  const areRowCellTemplatesEqual =
    prevProps.cells.map((c) => c?.value?.template || "").join("") ===
    nextProps.cells.map((c) => c?.value?.template || "").join("");

  // Short circuit to prevent more checks
  if (!areRowCellTemplatesEqual || !areRowCellValuesEqual) {
    return false;
  }

  const areColsEqual = prevProps.colsUniqueReconciliator === nextProps.colsUniqueReconciliator;
  const arePkValuesEqual = prevProps.pkName === nextProps.pkName;
  const areSkValuesEqual = prevProps.skName === nextProps.skName;

  const areViewsEqual = prevProps.currentView === nextProps.currentView;
  const areHighlightsEqual = prevProps.isHighlighted === nextProps.isHighlighted;
  const arePkHighlightsEqual = prevProps.isPartitionHighlighted === nextProps.isPartitionHighlighted;

  const areRowPropsEqual = JSON.stringify(prevProps.rowProps) === JSON.stringify(nextProps.rowProps);

  const areOriginalRowsEqual = JSON.stringify(prevProps.originalRow) === JSON.stringify(nextProps.originalRow);

  const areEqual =
    areViewsEqual &&
    areRowCellValuesEqual &&
    arePkValuesEqual &&
    areSkValuesEqual &&
    areRowPropsEqual &&
    areRowCellTemplatesEqual &&
    areOriginalRowsEqual &&
    areColsEqual &&
    areHighlightsEqual &&
    arePkHighlightsEqual;

  return areEqual;
};

const TableRow = memo((props: RowProps) => {
  const { rowProps, cells, pkName, skName } = props;

  const getContextMenuTriggerId = useCallback(
    (columnId: string) => {
      if (columnId === pkName) {
        return "partition-key-menu";
      } else if (columnId === skName) {
        return "sort-key-menu";
      }
      return "attribute-menu";
    },
    [pkName, skName],
  );

  return (
    <tr {...(rowProps as any)} className={props.isHighlighted ? "highlight" : ""}>
      {cells.map((cell: Cell) => {
        if ((cell as any).isRowSpanned) return null;
        else {
          return (
            <ContextMenuTrigger
              key={`${cell.column.id}-${cell.row.id}`}
              renderTag="td"
              attributes={
                {
                  role: "cell",
                  rowSpan: (cell as any).rowSpan,
                  style: {
                    backgroundColor:
                      cell.column.id === props.pkName && props.isPartitionHighlighted ? "#d6f0ff" : "initial",
                  },
                } as any
              }
              id={getContextMenuTriggerId(cell.column.id)}
              collect={() => cell}
            >
              {cell.render("Cell")}
            </ContextMenuTrigger>
          );
        }
      })}
    </tr>
  );
}, areEqual);

export default TableRow;
