import { SyntheticEvent } from "react";
import { MenuItem, SubMenu } from "react-contextmenu";
import { Cell } from "react-table";
import { ContextMenuProps } from "./ContextMenu";
import faker from "faker";

const GenerateValueSubMenu = ({ updateMyData }: ContextMenuProps) => (
  <SubMenu title="Generate Value">
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.internet.email(),
          type: "string",
        });
      }}
    >
      Email
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: new Date().toISOString(),
          type: "string",
        });
      }}
    >
      Datetime Now ISO
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.image.image(),
          type: "string",
        });
      }}
    >
      Image URL
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.internet.url(),
          type: "string",
        });
      }}
    >
      URL
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.lorem.sentence(),
          type: "string",
        });
      }}
    >
      Sentence
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.lorem.word(),
          type: "string",
        });
      }}
    >
      Word
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.phone.phoneNumber(),
          type: "string",
        });
      }}
    >
      Phone Number
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.datatype.uuid(),
          type: "string",
        });
      }}
    >
      UUID
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.datatype.number().toString(),
          type: "string",
        });
      }}
    >
      Number
    </MenuItem>
    <MenuItem
      onClick={(_: SyntheticEvent, cell: Cell) => {
        updateMyData(cell.row.index, cell.column.id, {
          value: faker.internet.ip(),
          type: "string",
        });
      }}
    >
      IP
    </MenuItem>
  </SubMenu>
);

export default GenerateValueSubMenu;
